// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAuth, connectAuthEmulator} from 'firebase/auth';
import {doc, collection, addDoc, getFirestore, connectFirestoreEmulator, getDoc} from 'firebase/firestore';
import {connectDatabaseEmulator, getDatabase} from 'firebase/database';
import { getFunctions, httpsCallable, connectFunctionsEmulator} from "firebase/functions";
import {QualifierPlayerData} from '../types/qualifier';
import {
    CONFIG_DOC,
    PEEPOSPRINT_COLLECTION,
    QUALIFIER_COLLECTION
} from '../../peepo-types/src/firestore/collection_const';
import {ConfigDocData} from '../../peepo-types/src';
import {isLocalhost, setDevMode} from '../utils/const';


const firebaseConfig = {
    apiKey: "AIzaSyD2Dbf6Oo7tfSj1NrGLEoOzdQzmbSQYoTM",
    authDomain: "peeposprint-at.firebaseapp.com",
    databaseURL: "https://peeposprint-at-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "peeposprint-at",
    storageBucket: "peeposprint-at.appspot.com",
    messagingSenderId: "95998907280",
    appId: "1:95998907280:web:63eaa50f36e3b999215915"
};




const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const rtDB = getDatabase(app);
const functions = getFunctions(app, 'europe-west3');



const auth = getAuth();

if (isLocalhost()) {
    console.log('Using local emulators for firestore and auth and database');
    connectFirestoreEmulator(db, 'localhost', 8089);
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectDatabaseEmulator(rtDB, 'localhost', 9000);
    connectFunctionsEmulator(functions, 'localhost', 5001);
} else {
    console.log('Using production firestore and auth');
}


export function getBaseDocPath(eventId: string) {
    return doc(db, PEEPOSPRINT_COLLECTION, eventId).path;
}

export function getConfigDocPath() {
    return doc(db, PEEPOSPRINT_COLLECTION, CONFIG_DOC).path;
}

export function getQualifierCollectionPath(eventId: string) {
    return collection(db, PEEPOSPRINT_COLLECTION, eventId, 'qualifier');
}

export function getLeaderboardCollection(eventId: string) {
    return collection(db, PEEPOSPRINT_COLLECTION, eventId, 'leaderboards');
}


export const addQualifier = async (eventId: string, playerData: QualifierPlayerData) => {
    const playersCollection = collection(db, PEEPOSPRINT_COLLECTION, eventId, QUALIFIER_COLLECTION);
    await addDoc(playersCollection, playerData);
};


export function callFunction(name: string, data: any) {
    return httpsCallable(functions, name)(data);
}

export function callUpdateLeaderboard() {
    return callFunction('qualifier-updateLeaderboard', {});
}



export const fetchConfig = async (): Promise<ConfigDocData> => {

    // Load the configuration from Firebase
    const docRef = doc(getFirestore(), 'peepoSprint', 'config');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data();

        const expectedFields = ['eventId', 'players', 'teams', 'format', 'startDate', 'currentLeaderboard', 'currentMap'];
        const allFieldsExist = expectedFields.every(field => field in data);

        if (!allFieldsExist) {
            console.warn('One or more fields are missing from the config data');
        }
        console.debug('Loaded config from Firebase', data);
        return data as unknown as ConfigDocData;
    } else {
        throw new Error('Could not find a config document in Firebase');
    }
}




