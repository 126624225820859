.container {
    background-image: url('../../assets/control/bg.jpg');
    height: 100vh;
}


.sideBar {
    width: 28%;
    margin-left: auto;
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginFormContainer {
    margin-top: 20px;
}
