import React, {createContext, useContext, useEffect, useState} from 'react';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {LOGIN_ROUTE} from '../../router/routerUrls';
import {useNavigate} from 'react-router-dom';
import {Logger, LogLevel} from '../../utils/Logger';
import {Loading} from '../Loading';

const LOGGER = new Logger(LogLevel.DEBUG, 'AuthProvider', false);
const AuthContext = createContext<{ user: any; loading: boolean }>({ user: null, loading: true });
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<any>(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoading(false);
            LOGGER.info('Auth state changed to ', user ? 'authenticated' : 'unauthenticated');
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if(!loading && !user) {
            navigate(LOGIN_ROUTE);
            LOGGER.debug('User is not authenticated, redirecting to login page');
        }
    }, [loading, user, navigate]);

    if (loading) {
        return <Loading />; // Create and import a Loading component
    }

    return (
        <AuthContext.Provider value={{ user, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
