import * as React from 'react';
import {Box} from '@mui/system';
import {CircularProgress} from '@mui/material';

type Props = {

};
export const Loading = (props: Props) => {
    return (
        <Box height={'100vh'} bgcolor={'var(--bgDark)'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress color="secondary" />
        </Box>
    );
};
