"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatType = exports.ProgressValues = exports.Checkpoint = exports.LeaderboardType = void 0;
var LeaderboardType;
(function (LeaderboardType) {
    LeaderboardType["GLOBAL_TEAM"] = "gt";
    LeaderboardType["GLOBAL_PLAYER"] = "gp";
    LeaderboardType["GLOBAL_BEST_TIME"] = "gbt";
    LeaderboardType["MAP_PLAYER"] = "mp";
    LeaderboardType["MAP_TEAM"] = "mt";
    LeaderboardType["QUALIFIER"] = "qualifier";
})(LeaderboardType || (exports.LeaderboardType = LeaderboardType = {}));
var Checkpoint;
(function (Checkpoint) {
    Checkpoint["START"] = "START";
    Checkpoint["NETHER_IN"] = "NETHER_IN";
    Checkpoint["END_IN"] = "END_IN";
    Checkpoint["FINISHED"] = "FINISHED";
    Checkpoint["SURRENDERED"] = "SURRENDERED";
})(Checkpoint || (exports.Checkpoint = Checkpoint = {}));
var ProgressValues;
(function (ProgressValues) {
    ProgressValues["BLAZE_ROD"] = "BLAZE_ROD";
    ProgressValues["ENDER_PERL"] = "ENDER_PERL";
    ProgressValues["ENDER_EYE"] = "ENDER_EYE";
    ProgressValues["DRAGON_HEALTH"] = "DRAGON_HEALTH";
})(ProgressValues || (exports.ProgressValues = ProgressValues = {}));
var StatType;
(function (StatType) {
    StatType["DEATHS"] = "DEATHS";
    StatType["DAMAGE_TAKEN"] = "DAMAGE_TAKEN";
    StatType["START"] = "START";
    StatType["NETHER_IN"] = "NETHER_IN";
    StatType["END_IN"] = "END_IN";
    StatType["FINISHED"] = "FINISHED";
    StatType["SURRENDERED"] = "SURRENDERED";
})(StatType || (exports.StatType = StatType = {}));
