import * as consts from '../utils/const';
import {ConsoleLogger, LogLevel} from 'peepo-types';
import {getAuth, getIdToken} from "firebase/auth";

const logger = new ConsoleLogger(LogLevel.DEBUG, 'PeepoRestClient', true);

export class PeepoRestClient {
    private static readonly playerName = 'ControlPanel';

    private static async request(url: string, options: RequestInit = {}): Promise<any> {
        let tokenId = await getAuth().currentUser.getIdToken(true);

        if (!tokenId) {
            return Promise.reject('No token found');
        }

        options.headers = {
            ...options.headers,
            'x-player-name': this.playerName,
            'x-user-token': tokenId
        };

        logger.info(`Sending request to ${url} with options`, options);

        try {
            const response = await fetch(url, options);
            const data = await response.json();

            if (!response.ok) {
                return Promise.reject(data);
            }

            logger.info(`Received response from ${url}`, data);
            return data;
        } catch (error) {
            logger.error(`Error while making request to ${url}`, error);
            return Promise.reject(error);
        }
    }

    public static async startMap(id: number): Promise<any> {
        return this.request(consts.getMapStartEndpoint(id), {method: 'POST'});
    }

     public static calculateLeaderboards(): Promise<any> {
         return this.request(consts.leaderboardCalculationEndpoint(), { method: 'POST' });
     }
}
