export enum LogLevel {
    ERROR = 0,
    WARN = 1,
    INFO = 2,
    SUCCESS = -1,
    DEBUG = 4,
    DEBUG_ADV
}

export class Logger {
    private readonly level: LogLevel;
    private readonly tag: string;
    private readonly enable: boolean;
    private readonly logFunction: (...args: any[]) => void;

    constructor(level = LogLevel.INFO, tag = '', enable = false, logFunction = console.log) {
        this.level = level || LogLevel.INFO;
        this.tag = tag || 'Logger';
        this.enable = enable;
        this.logFunction = logFunction;
    }

    private static colorize(level: LogLevel, message: string, tag: string) {
        const colors = {
            [LogLevel.ERROR]: '\x1b[31m', // Red
            [LogLevel.WARN]: '\x1b[33m', // Yellow
            [LogLevel.INFO]: '', // White
            [LogLevel.DEBUG]: '\x1b[90m', // Gray
            [LogLevel.SUCCESS]: '\x1b[32m', // Green
            [LogLevel.DEBUG_ADV]: '\x1b[90m', // Gray
        };
        const timestamp = new Date().toISOString().replace('T', ' ').substring(0, 19); // Format as 'YYYY-MM-DD HH:mm:ss'

        return `[${timestamp} ${tag} ${LogLevel[level]}]${colors[level]} ${message}\x1b[0m`;
    }

    private log(level: LogLevel, message: string, ...args: any[]) {
        if (this.enable && level <= this.level) {
            this.logFunction(Logger.colorize(level, message, this.tag), ...args);
        }
    }

    public error(message: string, ...args: any[]) {
        this.log(LogLevel.ERROR, message, ...args);
    }

    public warn(message: string, ...args: any[]) {
        this.log(LogLevel.WARN, message, ...args);
    }

    public info(message: string, ...args: any[]) {
        this.log(LogLevel.INFO, message, ...args);
    }

    public debugAdv(message: string, ...args: any[]) {
        this.log(LogLevel.DEBUG_ADV, message.length > 1000 ? message.substring(0, 1000) + "...." : message, ...args);
    }

    public success(message: string, ...args: any[]) {
        this.log(LogLevel.SUCCESS, message, ...args);
    }

    public debug(message: string, ...args: any[]) {
        this.log(LogLevel.DEBUG, message, ...args);
    }

    public static commandMessage(message: string) {
        return `[${new Date().toISOString().replace('T', ' ').substring(0, 19)} COMMAND] ${message}`;
    }
}

