import React from 'react';
import styles from './LoginPage.module.css';
import {PeepoLogo} from '../../components/PeepoLogo';
import {LoginForm} from './LoginForm';
import {Paper} from '@mui/material';


export const LoginPage: React.FC = () => {
    return (
        <div className={styles.container}>
            <Paper className={styles.sideBar}>
                    <PeepoLogo height={100}/>
                    <h3>peepoSprint<br/>Controlpanel</h3>
                <div className={styles.loginFormContainer}>
                    <LoginForm />
                </div>
            </Paper>
        </div>
    );
};
