import * as React from 'react';
import Box from '@mui/material/Box';
import {ConfigEditorPage} from './ConfigEditorPage';
import {ConfigEditProvider} from './form/ConfigEditProvider';

type Props = {};
export const ConfigurationPage = (props: Props) => {
    return (
        <div>
            <div style={{marginTop: 80, marginBottom: 80}}>
                <Box ml={2}>
                        <ConfigEditProvider>
                            <ConfigEditorPage/>
                        </ConfigEditProvider>
                </Box>
            </div>
        </div>
    );
};
