import React, {useState} from 'react';
import styles from '../config/ConfigurationPage.module.css';
import {EventCard} from './EventCard';
import {useNetworkHook} from '../../../hooks/useNetworkHook';
import {CreateEventForm} from './forms/CreateEventForm';
import {fetchBaseDocs} from '../../../api/domain/base_db';

export const Dashboard = () => {
    const [reren, setReren] = useState(false)
    const [data, loading, error] = useNetworkHook(fetchBaseDocs(), [reren]);

    const refresh = () => {
        setReren(!reren);
    }

    return (
        <div>
            <div className={styles.eventContainer}>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error.message}</p>}
                {data && data.map((doc, index) => <EventCard key={doc.eventId} data={doc}/>)}
                <CreateEventForm created={refresh} />
            </div>
        </div>
    );
};
