import React from 'react';
import './App.css';
import './firebase/firebase';
import {BrowserRouter as Router} from 'react-router-dom';
import {ConfigProvider} from './components/provider/ConfigProvider';
import {PeepoRoutes} from './router/Routes';
import {ScrollToAnchor} from './components/ScrollToAnchor';
import {BaseContainer} from './components/BaseContainer';
import {createTheme, CssBaseline, StyledEngineProvider, ThemeProvider} from '@mui/material';
import {AlertProvider} from './components/alert/AlertContext';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {AuthProvider} from './components/provider/AuthProvider';


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <StyledEngineProvider injectFirst>
                <CssBaseline/>
                <AlertProvider>
                    <div className="App">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Router>
                                <AuthProvider>
                                    <ConfigProvider>
                                        <ScrollToAnchor/>
                                        <BaseContainer>
                                            <PeepoRoutes/>
                                        </BaseContainer>
                                    </ConfigProvider>
                                </AuthProvider>
                            </Router>
                        </LocalizationProvider>
                    </div>
                </AlertProvider>
            </StyledEngineProvider>
        </ThemeProvider>
    );
}

export default App;
