import {useState, useEffect, DependencyList} from 'react';

export function useNetworkHook<T>(promise: Promise<T>, deps: DependencyList = []) {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<T | null>(null);

    useEffect(() => {
        let ignore = false;

        promise.then((response) => {
            if (!ignore) {
                setData(response);
                setError(null);
            }
        }).catch((err) => {
            if (!ignore) {
                setError(err);
            }
        }).finally(() => {
            if (!ignore) {
                setLoading(false);
            }
        });

        return () => {
            ignore = true;
        };
    }, deps);

    return [ data, loading, error ] as const;
}
