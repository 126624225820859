import React from 'react';
import styles from './PreviewPage.module.css';

type PreviewPageProps = {};

export const PreviewPage: React.FC<PreviewPageProps> = (props: PreviewPageProps) => {
    return (
        <div className={styles.container}>

        </div>
    );
};
