.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th {
    background-color: #121212;
    color: #ffffff;
    padding: 5px;
    text-align: center;
    border: 1px solid #ffffff;
}

.table td {
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 5px;
    text-align: center;
    border: 1px solid #ffffff;
}

.clipLink {
    color: #ffffff;
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
}

.clipLink:hover {
    color: #e1ad00;
}

.ratingStars {
    padding-right: 5px;
}