import {DEFAULT_CONFIG} from '../pages/util/create_data';
import {getAuth} from 'firebase/auth';
import {isLocalhost} from './const';

export function getPlayerImage(name: string) {

    return `https://peeposprint.at/assets/member/${name.toLowerCase()}.png`;
}

export function getTeam(team: number) {
    return DEFAULT_CONFIG.teams.find(t => t.id === team) || {
        id: -1,
        name: 'Unknown',
        color: '#000000',
        members: ['Unknown', 'Unknown', 'Unknown'],
    };
}


export const USER_ROLES = {
    ADMIN: 100,
    BANKO: 50,
    STUDIO: 10,
    MODERATOR: 5,
}

export const checkUserRole = (routeRole: number): boolean => {
    if(isLocalhost()) {
        return true;
    }
    const userEmail = getAuth().currentUser?.email || ''
    if (userEmail.startsWith('admin')) {
       return USER_ROLES.ADMIN >= routeRole;
    } else if (userEmail.startsWith('studio')) {
       return USER_ROLES.STUDIO >= routeRole;
    } else if (userEmail.startsWith('banko')) {
        return USER_ROLES.BANKO >= routeRole;
    }
    return USER_ROLES.MODERATOR >= routeRole;
};
