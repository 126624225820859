"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsoleLogger = void 0;
const Logger_1 = require("./Logger");
class ConsoleLogger extends Logger_1.AbstractLogger {
    constructor(level = Logger_1.LogLevel.INFO, tag = 'Logger', enable = false, useColor = true) {
        super(level, tag, enable, useColor);
    }
    logInfo(message, ...args) {
        console.log(message, ...args);
    }
    logDebug(message, ...args) {
        console.debug(message, ...args);
    }
    logWarn(message, ...args) {
        console.warn(message, ...args);
    }
    logError(message, ...args) {
        console.error(message, ...args);
    }
}
exports.ConsoleLogger = ConsoleLogger;
