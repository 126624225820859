// @ts-nocheck
import {Timestamp} from 'firebase/firestore';
import {MapDocument} from 'peepo-types';

export const map3Data: MapDocument = {
    mapId: 3,
    seed: "15817171968976319",
    start: Timestamp.now(),  // Current timestamp
    players: {
        "dimowo": {
            runId: "1243603958",
            checkpoint: "FINISHED",
            ticks: 27545,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 2,
                ENDER_PERL: 8
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 259,
                DEATHS: 3,
                END_IN: 25856,
                FINISHED: 27545,
                NETHER_IN: 4068
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Chaosflo44": {
            runId: "123544405",
            checkpoint: "FINISHED",
            ticks: 21629,
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 2,
                ENDER_PERL: 8
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 273,
                END_IN: 20491,
                FINISHED: 21629,
                NETHER_IN: 2484
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Dimeax": {
            runId: "828283024",
            checkpoint: "FINISHED",
            ticks: 15003,
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 1
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1384,
                END_IN: 13604,
                FINISHED: 15003,
                NETHER_IN: 2461
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "MultiPori": {
            runId: "474282733",
            checkpoint: "FINISHED",
            ticks: 14467,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 6,
                ENDER_PERL: 21
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 154,
                END_IN: 13265,
                FINISHED: 14467,
                NETHER_IN: 2541
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "TheFabo": {
            runId: "102804316",
            checkpoint: "FINISHED",
            ticks: 16702,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 26
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 105,
                END_IN: 15064,
                FINISHED: 16702,
                NETHER_IN: 3330
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Wichtiger": {
            runId: "1535761932",
            checkpoint: "NETHER_IN",
            ticks: 3203,
            progress: {
                BLAZE_ROD: 0,
                ENDER_EYE: 0,
                ENDER_PERL: 0
            },
            progressPoints: 22,
            stats: {
                DAMAGE_TAKEN: 117,
                DEATHS: 2,
                NETHER_IN: 3203
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Finnitz": {
            runId: "500293111",
            checkpoint: "END_IN",
            ticks: 26484,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 4
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 880,
                DEATHS: 3,
                END_IN: 26484,
                NETHER_IN: 2329
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "noooreax": {
            runId: "1483210705",
            checkpoint: "FINISHED",
            ticks: 24466,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 2,
                ENDER_PERL: 4
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 363,
                DEATHS: 2,
                END_IN: 23017,
                FINISHED: 24466,
                NETHER_IN: 4282
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Faister": {
            runId: "111223344",
            checkpoint: "FINISHED",
            ticks: 15678,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 12
            },
            progressPoints: 60,
            stats: {
                DAMAGE_TAKEN: 1350,
                END_IN: 14500,
                FINISHED: 15678,
                NETHER_IN: 3500
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Papaplatte": {
            runId: "222334455",
            checkpoint: "FINISHED",
            ticks: 17222,
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 5,
                ENDER_PERL: 20
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1450,
                END_IN: 15500,
                FINISHED: 17222,
                NETHER_IN: 3200
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "mcsr_misfit": {
            runId: "333445566",
            checkpoint: "END_IN",
            ticks: 19000,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 4,
                ENDER_PERL: 15
            },
            progressPoints: 58,
            stats: {
                DAMAGE_TAKEN: 1400,
                END_IN: 17000,
                FINISHED: 0,
                NETHER_IN: 3000
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Nentox": {
            runId: "444556677",
            checkpoint: "FINISHED",
            ticks: 16789,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 6,
                ENDER_PERL: 22
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1500,
                END_IN: 15500,
                FINISHED: 16789,
                NETHER_IN: 3400
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        }
    },
    queue: {
        "Chaosflo44": Timestamp.now(),
        "TheFabo": Timestamp.now(),
        "Wichtiger": Timestamp.now(),
        "noooreax": Timestamp.now(),
        "Papaplatte": Timestamp.now(),
        "Faister": Timestamp.now()
    }
};
