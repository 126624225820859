import React from 'react';
import styles from './HighlightsPage.module.css';
import {Button, MenuItem, Select} from "@mui/material";
import {RateHighlight} from "./rate/RateHighlight";
import {HighlightList} from "./list/HighlightList";

type StudioPageProps = {};

export const HighlightsPage: React.FC<StudioPageProps> = (props) => {
    const [showRate, setShowRate] = React.useState(false);
    const [highlightCount, setHighlightCount] = React.useState(10);

    return (
        <div className={styles.container}>
            <div className={styles.buttonContainer}>
                <Button onClick={() => setShowRate(true)} variant="contained" color="primary">
                    Rate next Highlight
                </Button>
                <Select
                    value={highlightCount}
                    onChange={(e) => setHighlightCount(Number.parseInt(e.target.value.toString()))}
                    label="Highlight Count"
                >
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                </Select>
            </div>
            {showRate && <RateHighlight setShowRate={setShowRate}/>}
            <HighlightList highlightCount={highlightCount}/>
        </div>
    );
};
