import {
    collection, doc,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query, runTransaction,
    Timestamp, updateDoc,
    where
} from "firebase/firestore";
import {PeepoSprintConfigData} from "../../utils/PeepoSprintConfigData";
import {Highlight} from "../../../typings/highlights";
import {getBaseDocPath} from "../../firebase/firebase";
import {MapLogEntry} from "../../../peepo-types/src";


export const fetchNextUnratedHighlight = async () => {
    const db = getFirestore();
    const configInstance = await PeepoSprintConfigData.getInstance();
    const baseDocRef = getBaseDocPath(configInstance.eventId);

    const highlightsCollectionPath = `${baseDocRef}/highlights`;

    // Create a query
    const q = query(
        collection(db, highlightsCollectionPath),
        where('rating', '==', -1),
        where('blocked', '<=', Timestamp.now()),
        orderBy('blocked'),
        orderBy('timestamp'),
        limit(1)
    );

    const docs = (await getDocs(q)).docs;

    console.log(docs.length)

    if (docs.length === 0) {
        throw new Error('No unrated highlights found');
    }

    const doc = docs[0];

    await updateDoc(doc.ref, {
        blocked: Timestamp.fromMillis(Date.now() + 1000 * 90)
    });

    const highlight = doc.data() as Highlight;
    highlight.id = doc.id;
    return highlight;
}

export const fetchRatedHighlights = async (maxEntries: number = 20) => {

    const db = getFirestore();
    const configInstance = await PeepoSprintConfigData.getInstance();
    const baseDocRef = getBaseDocPath(configInstance.eventId);

    try {
        const highlightsCollectionPath = `${baseDocRef}/highlights`;

        // Create a query
        const q = query(
            collection(db, highlightsCollectionPath),
            where('rating', '!=', -1),
            orderBy('rating', 'desc'),
            orderBy('timestamp', 'asc'),
            limit(maxEntries)
        );

        const docs = (await getDocs(q)).docs;
        return docs.map(snapshot => {
            const highlight = snapshot.data() as Highlight;
            highlight.id = snapshot.id;
            return highlight;
        });
    } catch (error) {
        console.error('Error fetching unrated highlight: ', error);
        throw error; // Rethrow the error for further handling
    }
};

export const rateHighlight = async (id: string, rating: number) => {
    const db = getFirestore();
    const configInstance = await PeepoSprintConfigData.getInstance();
    const baseDocRef = getBaseDocPath(configInstance.eventId);

    try {
        const highlightsDocumentPath = `${baseDocRef}/highlights/${id}`;
        const element = doc(db, highlightsDocumentPath);
        await updateDoc(element, {
            rating: rating
        });

        return true;
    } catch (error) {
        console.error('Error fetching unrated highlight: ', error);
        throw error; // Rethrow the error for further handling
    }
}
