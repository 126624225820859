import React, {useEffect, useState} from 'react';
import styles from './MapsPage.module.css';
import {MapCard} from './MapCard';
import {useNetworkHook} from '../../../hooks/useNetworkHook';
import {getMaps} from '../../../api/domain/map_db';
import {useAlert} from '../../../components/alert/AlertContext';
import {Typography} from '@mui/material';
import {Logger, LogLevel} from '../../../utils/Logger';
import {useConfig} from '../../../components/provider/ConfigProvider';
import {CreateMapButton} from './CreateMapButton';

type MapsPageProps = {};
const logger = new Logger(LogLevel.INFO, 'MapsPage', false);

export const MapsPage: React.FC<MapsPageProps> = (props: MapsPageProps) => {
    const providerConfigData = useConfig();
    const [reren, setReren] = useState(false);
    const [maps, loading, error] = useNetworkHook(getMaps(), [providerConfigData.currentMap, reren]);
    const alerts = useAlert();
    const currentMap = providerConfigData.currentMap;
    const [nextMapId, setNextMapId] = useState(0);

    useEffect(() => {
        if (maps) {
            if(maps.length === 0) {
                setNextMapId(1);
            } else {
                const cmapId = Math.max(...maps.map(map => map.mapId));
                setNextMapId(cmapId + 1);
            }
        }
    }, [maps]);

    useEffect(() => {
        if (error) {
            logger.error('Error during loading maps:', error);
            alerts.addAlert('Error loading maps', 'error');
        }
    }, [error]);

    const handleMapCreated = () => {
        setReren(prev => !prev);
    };

    return (
        <div className={styles.container}>
            <Typography variant="body1" paragraph>
                In the peepoSprint Map menu, the maps are controlled.
            </Typography>
            <Typography variant="body1" paragraph>
                - By <b>"Activating"</b> a map, it is set as the Queue Map.
            </Typography>
            <Typography variant="body1" paragraph>
                - By <b>"Start Map"</b>, the map is started (Map Timer begins and the clients load in)
            </Typography>
            <Typography variant="body1" paragraph>
                - <b>"Started Map"</b>, are highlighted in gray with a red border.
            </Typography>
            <div className={styles.createMapDocumentButton}>
                <CreateMapButton mapId={nextMapId} onMapCreated={handleMapCreated}/>
            </div>


            <div className={styles.seedContainer}>
                {loading && <p>Loading maps...</p>}
                {maps && providerConfigData && maps.sort((map, map2) => map.mapId - map2.mapId)
                    .map((map) => <MapCard key={map.mapId} data={map} active={currentMap === map.mapId} rerender={() => handleMapCreated()}/>)}
            </div>
        </div>
    );
};
