import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField} from '@mui/material';
import styles from './PlayerCard.module.css';
import {Player, Social} from 'peepo-types';
import DeleteIcon from '@mui/icons-material/Delete';
import {SocialInput} from './SocialInput';

import {Box} from '@mui/system';
import {useConfigEditor} from '../form/ConfigEditProvider';
import {useConfig} from '../../../../components/provider/ConfigProvider';

type Props = {
    data: Player;
    onRemove: (name: string) => void;
};

const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};


export const PlayerCard = ({data, onRemove}: Props) => {
    const configData = useConfig();
    const {setData, updateConfig} = useConfigEditor();
    const [playerData, setPlayerData] = useState(data);
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        setIsEdited(!deepEqual(data, playerData));
    }, [playerData, data]);

    const handleChange = (field: string, value: any) => {
        const updatedPlayer = {...playerData, [field]: value};
        setPlayerData(updatedPlayer);
    };

    const addSocial = () => {
        const newSocial: Social = {type: 'TWITCH', url: ''};
        handleChange('socials', [...playerData.socials, newSocial])
    };

    const removeSocial = (index: number) => {
        const filteredSocials = playerData.socials.filter((_, idx) => idx !== index);
        handleChange('socials', filteredSocials);
    };

    const handleSocialChange = (index: number, newSocial: Social) => {
        const updatedSocials = playerData.socials.map((social, idx) => (idx === index ? newSocial : social));
        handleChange('socials', updatedSocials);
    };

    const savePlayer = () => {
        const updatedPlayers = configData.players.map(player =>
            player.name === data.name ? playerData : player
        );
        updateConfig({players: updatedPlayers});
        setData({...configData, players: updatedPlayers});
    }

    return (
        <Paper className={styles.paper}>
            <Box display={'flex'} gap={2} alignItems={'top'}>
                <h2 className={styles.playerName}>{data.name || '<Unknown>'}</h2>
                <FormControl variant="outlined" className={styles.selector}>
                    <InputLabel>Category</InputLabel>
                    <Select
                        value={playerData.category}
                        onChange={(e) => handleChange('category', e.target.value)}
                        label="Category"
                    >
                        <MenuItem value="pro">Pro</MenuItem>
                        <MenuItem value="streamer">Streamer</MenuItem>
                        <MenuItem value="medium">Medium</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <div className={styles.fieldContainer}>
                <Box display={'flex'} gap={5}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        value={playerData.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        className={styles.inputField}
                    />
                    <TextField
                        label="Player Name"
                        variant="outlined"
                        value={playerData.ingName}
                        onChange={(e) => handleChange('ingName', e.target.value)}
                        className={styles.inputField}
                        inputProps={{pattern: '^[a-zA-Z0-9]+$'}}
                    />
                </Box>
            </div>
            {playerData.socials.map((social, index) => (
                <SocialInput
                    key={index}
                    index={index}
                    social={social}
                    onSocialChange={handleSocialChange}
                    onRemove={removeSocial}
                />
            ))}
            <Box mt={4} display={'flex'} justifyContent={'flex-end'}>
                <Button ia-label="delete" color="error" variant={'outlined'} className={styles.deleteButton}
                        onClick={() => onRemove(data.name)}>
                    <DeleteIcon/>
                </Button>
                <Button onClick={addSocial} variant="contained" color="primary">
                    Add Social
                </Button>
                <Button onClick={savePlayer} variant="contained" color="warning" sx={{ml: 2}} disabled={!isEdited}>
                    Save Player
                </Button>
            </Box>
        </Paper>
    );
};
