// @ts-nocheck
import {Timestamp} from 'firebase/firestore';
import {MapDocument} from 'peepo-types';

export const  map1Data: MapDocument = {
    mapId: 1,
    seed: "714915422299433094",
    start: Timestamp.now(),  // Current timestamp
    players: {
        "Chaosflo44": {
            runId: "1390594107",
            checkpoint: "FINISHED",
            ticks: 24820,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 110.368,
                ENDER_EYE: 5,
                ENDER_PERL: 12
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 885,
                DEATHS: 1,
                END_IN: 10563,
                FINISHED: 24820,
                NETHER_IN: 1211
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "Nentox": {
            runId: '710328160',
            checkpoint: "FINISHED",
            ticks: 12983,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 6,
                ENDER_PERL: 34
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1266,
                END_IN: 11502,
                FINISHED: 12983,
                NETHER_IN: 1442
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "TheFabo": {
            runId: "341877912",
            checkpoint: "FINISHED",
            ticks: 10663,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 16
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 159,
                END_IN: 9216,
                FINISHED: 10663,
                NETHER_IN: 990
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "MultiPori": {
            runId: "123456789",
            checkpoint: "END_IN",
            ticks: 9000,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 50.0,
                ENDER_EYE: 2,
                ENDER_PERL: 10
            },
            progressPoints: 40,
            stats: {
                DAMAGE_TAKEN: 500,
                END_IN: 9000,
                FINISHED: 0,
                NETHER_IN: 2000
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "Wichtiger": {
            runId: "889979532",
            checkpoint: "END_IN",
            ticks: 10206,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 80,
                ENDER_EYE: 3,
                ENDER_PERL: 1
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1060,
                END_IN: 10206,
                NETHER_IN: 1082
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "Dimeax": {
            runId: "999999999",
            checkpoint: "FINISHED",
            ticks: 15000,
            progress: {
                BLAZE_ROD: 2,
                DRAGON_HEALTH: 20,
                ENDER_EYE: 4,
                ENDER_PERL: 14
            },
            progressPoints: 58,
            stats: {
                DAMAGE_TAKEN: 900,
                DEATHS: 0,
                END_IN: 8000,
                FINISHED: 15000,
                NETHER_IN: 3000
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "noooreax": {
            runId: "710328160",
            checkpoint: "FINISHED",
            ticks: 12983,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 6,
                ENDER_PERL: 34
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1266,
                END_IN: 11502,
                FINISHED: 12983,
                NETHER_IN: 1442
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "dimowo": {
            runId: "888888888",
            checkpoint: "FINISHED",
            ticks: 11000,
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 10,
                ENDER_EYE: 5,
                ENDER_PERL: 18
            },
            progressPoints: 55,
            stats: {
                DAMAGE_TAKEN: 800,
                DEATHS: 2,
                END_IN: 7000,
                FINISHED: 11000,
                NETHER_IN: 2500
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "Papaplatte": {
            runId: "777777777",
            checkpoint: "FINISHED",
            ticks: 9500,
            progress: {
                BLAZE_ROD: 3,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 4,
                ENDER_PERL: 15
            },
            progressPoints: 70,
            stats: {
                DAMAGE_TAKEN: 700,
                DEATHS: 1,
                END_IN: 6000,
                FINISHED: 9500,
                NETHER_IN: 2300
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "Finnitz": {
            runId: "666666666",
            checkpoint: "FINISHED",
            ticks: 10500,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 12
            },
            progressPoints: 50,
            stats: {
                DAMAGE_TAKEN: 850,
                DEATHS: 2,
                END_IN: 5000,
                FINISHED: 10500,
                NETHER_IN: 2200
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "Faister": {
            runId: "555555555",
            checkpoint: "FINISHED",
            ticks: 9800,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 2,
                ENDER_PERL: 10
            },
            progressPoints: 45,
            stats: {
                DAMAGE_TAKEN: 600,
                DEATHS: 0,
                END_IN: 4000,
                FINISHED: 9800,
                NETHER_IN: 2000
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        },
        "mcsr_misfit": {
            runId: "444444444",
            checkpoint: "FINISHED",
            ticks: 11500,
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 10,
                ENDER_EYE: 3,
                ENDER_PERL: 8
            },
            progressPoints: 48,
            stats: {
                DAMAGE_TAKEN: 700,
                DEATHS: 1,
                END_IN: 4500,
                FINISHED: 11500,
                NETHER_IN: 2400
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
            progressHistory: {}
        }
    },
    queue: {
        "Chaosflo44": Timestamp.now(),
        "Wichtiger": Timestamp.now(),
        "TheFabo": Timestamp.now(),
        "Papaplatte": Timestamp.now(),
        "Faister": Timestamp.now(),
        "noooreax": Timestamp.now()
    }
};
