import {useEffect, useState} from 'react';
import {listenMapDocument} from '../api/domain/map_db';
import {MapDocument} from 'peepo-types';

type NetworkState = {
    data: MapDocument | undefined;
    loading: boolean;
    error: Error | undefined;
};

export const useMapDoc = (mapId: number) => {
    const [networkState, setNetworkState] = useState<NetworkState>({
        data: undefined,
        loading: true,
        error: undefined,
    });

    useEffect(() => {
        let isMounted = true; // To prevent state update if component is unmounted

        const fetchData = async () => {
            try {
                return await listenMapDocument(mapId, (data) => {
                    if (isMounted) {
                        setNetworkState({
                            data: data,
                            loading: false,
                            error: undefined,
                        });
                    }
                });
            } catch (error) {
                if (isMounted) {
                    setNetworkState({
                        data: null,
                        loading: false,
                        error: error,
                    });
                }
            }
        };

        const unsubscribePromise = fetchData();

        return () => {
            isMounted = false;
            unsubscribePromise.then((unsubscribe) => unsubscribe());
        };
    }, [mapId]);

    return networkState;
};
