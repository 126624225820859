// QualifierList.tsx
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { fetchQualifiers } from '../../../api/domain/qualifier_db';
import { QualifierPlayerData } from '../../../types/qualifier';
import {useConfig} from '../../../components/provider/ConfigProvider';

type Props = {};

export const QualifierList: React.FC<Props> = () => {
    const [players, setPlayers] = useState<QualifierPlayerData[]>([]);
    const [lastVisible, setLastVisible] = useState<any>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const isMounted = useRef(true);
    const peepoSprintConfig = useConfig();

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            const { playerData, newLastVisible } = await fetchQualifiers(peepoSprintConfig.eventId, page, rowsPerPage, lastVisible);
            if (isMounted.current) {
                setPlayers(playerData);
                setLastVisible(newLastVisible);
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const obfuscate = (str) => {
        if (str.length <= 2) return str + '***';
        return str.substring(0, 2) + '*'.repeat(str.length - 2);
    };

    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Player Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Identifier</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {players.map((player) => (
                            <TableRow key={player.id}>
                                <TableCell>{player.id}</TableCell>
                                <TableCell>{player.playerName}</TableCell>
                                <TableCell>{obfuscate(player.email)}</TableCell>
                                <TableCell>{obfuscate(player.identifier)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={-1} // You can set this to a high number if you want to disable the total count
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};
