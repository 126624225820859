import React from 'react';
import {useLeaderboards} from '../../../hooks/useLeaderboards';
import {Button} from '@mui/material';

type RTDatabasePageProps = {};

export const RTDatabasePage: React.FC<RTDatabasePageProps> = (props) => {
    const { data: leaderboard, loading, error } = useLeaderboards();
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {JSON.stringify(error)}</div>;
    }

    return (
        <div>
            <h1>Leaderboard</h1>
            <div>
                <Button variant="contained" color="primary" onClick={() => console.log('clicked')}>Recalculate Leaderboards</Button>
            </div>
            <pre>{JSON.stringify(leaderboard, null, 2)}</pre>
        </div>
    );
};
