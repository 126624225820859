import {useEffect, useState} from 'react';
import {getDatabase, DataSnapshot, ref, onValue} from 'firebase/database';
import {RTLeaderboard, RTLeaderboardGlobalEntry, RTLeaderboardMapEntry} from 'peepo-types';

type NetworkState<T> = {
    data: T | null;
    loading: boolean;
    error: Error | null;
};

type LBsType = RTLeaderboard<RTLeaderboardGlobalEntry | RTLeaderboardMapEntry>[];
export const useLeaderboards = () => {
    const [networkState, setNetworkState] = useState<NetworkState<LBsType>>({
        data: null,
        loading: true,
        error: null,
    });

    useEffect(() => {
        const db = getDatabase();
        const leaderboardRef = ref(db, 'leaderboard');

        const unsubscribe = onValue(leaderboardRef, (snapshot: DataSnapshot) => {
            setNetworkState({
                data: snapshot.val() as LBsType,
                loading: false,
                error: null,
            });
        }, (errorObject: Error) => {
            console.error('The read failed: ' + errorObject.name);
            setNetworkState({
                data: null,
                loading: false,
                error: errorObject,
            });
        });

        return () => {
            unsubscribe()
        };
    }, []);

    return networkState;
};
