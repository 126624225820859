import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import styles from './TeamCard.module.css';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { Player, Team } from 'peepo-types';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import { useConfigEditor } from '../form/ConfigEditProvider';

type Props = {
    data: Team;
    onRemove: (id: number) => void;
};

const deepEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const TeamCard = ({ data, onRemove }: Props) => {
    const { data: configData, setData, updateConfig } = useConfigEditor();
    const availablePlayers = configData.players.filter(player => !data.members.includes(player.name) && player.team === -1);
    const [selectedPlayer, setSelectedPlayer] = useState(availablePlayers[0]);
    const [teamData, setTeamData] = useState(data);
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        setTeamData(data);
    }, [data]);

    useEffect(() => {
        setIsEdited(!deepEqual(data, teamData));
    }, [teamData, data]);

    const handleChange = (field: string, value: any) => {
        const updatedTeam = { ...teamData, [field]: value };
        setTeamData(updatedTeam);
    };

    const addPlayer = () => {
        const name = selectedPlayer.name;
        setSelectedPlayer(availablePlayers.length ? availablePlayers[0] : undefined);
        handleChange('members', [...teamData.members, name]);
    };

    const removePlayer = (index: number) => {
        const filteredMembers = teamData.members.filter((_, idx) => idx !== index);
        handleChange('members', filteredMembers);
    };

    const saveChanges = () => {
        const updatedTeams = configData.teams.map(team =>
            team.id === data.id ? teamData : team
        );
        setIsEdited(false);
        updateConfig({ teams: updatedTeams});
    };

    const onPlayerSelect = (name: string) => {
        if (name === 'none') {
            console.warn('Cannot select fallback value none for player');
            return;
        }
        setSelectedPlayer(availablePlayers.find(player => player.name === name));
    };

    return (
        <Paper className={`${styles.paper} ${isEdited ? styles.edited : ''}`}>
            <h4>{data.name || `Team ${data.id}`}</h4>
            <Button aria-label="delete" color="error" variant={'contained'} className={styles.deleteButton} onClick={() => onRemove(data.id)}>
                <DeleteIcon />
            </Button>
            <div className={styles.fieldContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <TextField
                            label="Team ID"
                            type="number"
                            variant="outlined"
                            value={teamData.id.toString()}
                            onChange={(e) => handleChange('id', parseInt(e.target.value))}
                            className={styles.inputField}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Color"
                            variant="outlined"
                            value={teamData.color}
                            onChange={(e) => handleChange('color', e.target.value)}
                            className={styles.inputField}
                        />
                    </Grid>
                </Grid>
                <TextField
                    label="Team Name"
                    variant="outlined"
                    value={teamData.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    className={styles.inputField}
                />
            </div>

            {teamData.members.map((member, index) => (
                <Grid container mt={2} key={index}>
                    <Grid item xs={12} md={10}>
                        <TextField
                            label="Player"
                            variant="outlined"
                            disabled={true}
                            value={member}
                            className={styles.inputField}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} display={'flex'} alignItems={'center'}>
                        <Button aria-label="delete" color="error" onClick={() => removePlayer(index)}>
                            <ClearIcon />
                        </Button>
                    </Grid>
                </Grid>
            ))}
            <Box width={'100%'} mt={2} mb={2}>
                <Divider />
            </Box>

            <Grid container mt={2}>
                <Grid item xs={12} md={10}>
                    <FormControl variant="outlined" className={styles.inputField}>
                        <InputLabel>Player</InputLabel>
                        <Select
                            value={selectedPlayer?.name ? selectedPlayer?.name : 'none'}
                            onChange={(e) => onPlayerSelect(e.target.value)}
                            label="Player"
                        >
                            {!availablePlayers.length && <MenuItem value={'none'} disabled>No players available</MenuItem>}
                            {availablePlayers.map((player) => (
                                <MenuItem key={player.name} value={player.name}>
                                    {player.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={2} display={'flex'} alignItems={'center'}>
                    <Button aria-label="add" color="primary" onClick={addPlayer}>
                        <AddIcon />
                    </Button>
                </Grid>
            </Grid>
            {isEdited && (
                <Button onClick={saveChanges} variant="contained" color="secondary">
                    Save
                </Button>
            )}
        </Paper>
    );
};
