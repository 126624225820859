import {ConfigDocData, EventFormat, Player, Team, Timestamp} from 'peepo-types';


export class ProviderConfigData {
    public eventId: string;
    public players: Player[];
    public teams: Team[];
    public format: EventFormat;
    public currentMap: number;
    public currentLeaderboard: number;
    public startDate: Timestamp;

    public constructor(data: ConfigDocData) {
        this.eventId = data.eventId;
        this.players = data.players;
        this.teams = data.teams;
        this.format = data.format;
        this.startDate = data.startDate;
        this.currentMap = data.currentMap;
        this.currentLeaderboard = data.currentLeaderboard;
    }

    public getPlayerImage(name: string) {
        return `https://peeposprint.at/assets/member/${name.toLowerCase()}.png`;
    }

    public getTeam(ingName: string) {
        let find = this.teams.find(team => team.members.includes(ingName));
        if (!find) {
            return {
                id: 0,
                name: 'Unknown',
                color: '#808080',
                members: [ingName, 'Unknown']
            }
        }
        return find;
    }

    public getTeamById(id: number) {
        let find = this.teams.find(team => team.id === id);
        if (!find) {
            return {
                id: 0,
                name: 'Unknown',
                color: '#808080',
                members: ['Unknown', 'Unknown']
            }
        }
        return find;
    }


    public getPlayer(ingName: string) {
        let find = this.players.find(team => team.ingName === ingName);
        if (!find) {
            return {
                name: 'Unknown',
                ingName: 'Unknown',
                socials: ['Unknown', 'Unknown'],
                team: 0,
                category: 'Unknown'
            }
        }
        return find;
    }


}
