.container {
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    align-content: center;
}

.buttonContainer {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;
}

