import React, {useState} from 'react';
import {Button} from '@mui/material';
import {useAlert} from '../../../components/alert/AlertContext';
import {bulkAddTestMembers} from '../../../api/domain/qualifier_db';
import {leaderBoardTestMembers} from './qualifier_service';
import {useConfig} from '../../../components/provider/ConfigProvider';

type Props = {};

export const AddTestMembersButton: React.FC<Props> = (props: Props) => {
    const [isFirstClick, setIsFirstClick] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const alerts = useAlert();
    const peepoSprintConfig = useConfig();

    const handleClick = async () => {
        if (isFirstClick) {
            setIsFirstClick(false);
        } else {
            setIsDisabled(true);
            try {
                await bulkAddTestMembers(peepoSprintConfig.eventId, leaderBoardTestMembers);
                alerts.addAlert(`Test members ${leaderBoardTestMembers.length} added successfully!`, 'success');
            } catch (error) {
                alerts.addAlert('Error adding test members.', 'error');
            }
        }
    };

    return (
        <Button
            variant="contained"
            color={isFirstClick ? 'primary' : 'error'}
            onClick={handleClick}
            disabled={isDisabled}
        >
            {isFirstClick ? 'Add Test Members' : 'Confirm Adding Test Members'}
        </Button>
    );
};
