"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONFIG_DOC = exports.LEADERBOARDS_COLLECTION = exports.QUALIFIER_LEADERBOARD_ID = exports.QUALIFIER_COLLECTION = exports.LOGS_COLLECTION = exports.MAPS_COLLECTION = exports.PEEPOSPRINT_COLLECTION = void 0;
exports.PEEPOSPRINT_COLLECTION = 'peepoSprint';
exports.MAPS_COLLECTION = 'maps';
exports.LOGS_COLLECTION = 'logs';
exports.QUALIFIER_COLLECTION = 'qualifier';
exports.QUALIFIER_LEADERBOARD_ID = 'qualifier';
exports.LEADERBOARDS_COLLECTION = 'leaderboards';
exports.CONFIG_DOC = 'config';
