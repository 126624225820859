import React from 'react';
import { Button } from '@mui/material';
import { useAlert } from '../../../components/alert/AlertContext';
import {PeepoRestClient} from '../../../api/PeepoRestClient';
import {useConfig} from "../../../components/provider/ConfigProvider";
import {setSeed} from "../../../api/domain/map_db";

type StartMapButtonProps = {
    mapId: number;
    started: boolean;
    seed: string;
    onStart: () => void;
};

export const StartMapButton: React.FC<StartMapButtonProps> = ({ mapId, seed, started, onStart }) => {
    const { addAlert } = useAlert();


    const handleClick = async () => {
        if(started) {
            addAlert("Map already started", "warning");
            return;
        }

        try {
            if (!seed) {
                const newSeed = prompt("There is no seed set for this map. Please enter a seed:")

                if (newSeed === null) {
                    addAlert("No seed entered. Please try again.", "error");
                    return;
                }

                if (Number.isNaN(newSeed) || !Number(newSeed)) {
                    addAlert("Seed must be a number.", "error");
                    return;
                }

                await setSeed(mapId, newSeed);
            }

            await PeepoRestClient.startMap(mapId);
            addAlert(`Started map ${mapId} successfully!!`, "success");
            onStart();
        } catch (error) {
            console.error("Failed to update map status:", error);
            addAlert("Failed to update map status. Please try again. ", "error");
        }
    };

    return (
        <Button variant={'outlined'} disabled={started} onClick={handleClick}>
            {started ? 'Map started' : 'Start Map'}
        </Button>
    );
};
