// qualifierDao.ts
import {addDoc, doc, getDoc, getDocs, limit, orderBy, query, startAfter} from 'firebase/firestore';
import {QualifierLeaderboard, QualifierPlayerData} from '../../types/qualifier';
import {getLeaderboardCollection, getQualifierCollectionPath} from '../../firebase/firebase';
import {QUALIFIER_LEADERBOARD_ID} from '../../../peepo-types/src/firestore/collection_const';

export const fetchQualifiers = async (eventId: string, page: number, rowsPerPage: number, lastVisible: any = null) => {
    const playersCollection = getQualifierCollectionPath(eventId);
    let playerQuery;

    if (lastVisible) {
        playerQuery = query(playersCollection, orderBy('playerName'), startAfter(lastVisible), limit(rowsPerPage));
    } else {
        playerQuery = query(playersCollection, orderBy('playerName'), limit(rowsPerPage));
    }

    const snapshot = await getDocs(playerQuery);
    const playerData = snapshot.docs.map(doc => {
            const data = doc.data() as QualifierPlayerData;
            data.id = doc.id;
            return data;
        }
    );
    const newLastVisible = snapshot.docs[snapshot.docs.length - 1];

    return {playerData, newLastVisible};
};

export const fetchLeaderboard = async (eventId: string) => {
    const leaderboardCollection = getLeaderboardCollection(eventId);
    const docRef = doc(leaderboardCollection, QUALIFIER_LEADERBOARD_ID);
    const d = await getDoc(docRef);
    return d.data() as QualifierLeaderboard;
}


export const bulkAddTestMembers = async (eventId: string, members: QualifierPlayerData[]) => {
    const playersCollection = getQualifierCollectionPath(eventId);
    const addMemberPromises = members.map(member => addDoc(playersCollection, member));
    await Promise.all(addMemberPromises);
};
