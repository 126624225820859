import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { fetchLeaderboard } from '../../../api/domain/qualifier_db';
import { useConfig } from '../../../components/provider/ConfigProvider';

type Props = {};

export const QualifierLeaderboard = (props: Props) => {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const peepoSprintConfig = useConfig();

    const [fetchedData, setFetchedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await fetchLeaderboard(peepoSprintConfig.eventId);
                setFetchedData(data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [peepoSprintConfig.eventId]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Rank</TableCell>
                            <TableCell>Player Name</TableCell>
                            <TableCell>ELO Rate</TableCell>
                            <TableCell>Last Ranked</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        {error && (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    <Typography color="error">Error loading data</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {!loading && !error && fetchedData?.results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.rank}</TableCell>
                                <TableCell>{row.playerName}</TableCell>
                                <TableCell>{row.eloRate}</TableCell>
                                <TableCell>{row.lastRanked ? new Date(row.lastRanked).toLocaleString() : ''}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]} // Only one option for rows per page
                component="div"
                count={fetchedData ? fetchedData.results.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </Paper>
    );
};
