
 let isDev = isLocalhost();

export const DRAW_WIDTH = 240;

export const BASE_URL = isDev ? 'http://localhost:8080' : 'https://gb.peeposprint.at';
export const getMapStartEndpoint = (id: number) => `${BASE_URL}/api/maps/${id}/start`;
export const leaderboardCalculationEndpoint = () => `${BASE_URL}/api/leaderboards/calculation`;


 export function setDevMode() {
     isDev = true;
 }

 export function isDevMode() {
     return isDev;
 }

 export function isLocalhost() {
     const hostname = window.location.hostname;
     return (hostname === 'localhost' || hostname === '127.0.0.1');
 }
