import React from 'react';
import styles from './Queue.module.css';
import {useMapDoc} from '../../../hooks/useMapDoc';
import {Avatar, ListItem, ListItemAvatar, ListItemText, Stack, Typography} from '@mui/material';
import {useConfig} from '../../../components/provider/ConfigProvider';
import {Box} from '@mui/system';

type QueueProps = {
    mapId?: number;
};

export const Queue: React.FC<QueueProps> = ({mapId}) => {
    const {data, error, loading} = useMapDoc(mapId);
    const providerConfigData = useConfig();
    const entries = data ? Object.entries(data.queue) : [];

    const formattedTimestamp = (date: Date) => {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const milliseconds = date.getMilliseconds().toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const chunkArray = (array: any[], chunkSize: number) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const entryChunks = chunkArray(entries, 2);

    return (
        <div className={styles.container}>
            <h4>Queue</h4>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {data && (
                <Stack direction={'row'} spacing={2}>
                    {entryChunks.map((chunk, index) => (
                        <Stack key={index} direction={'column'} spacing={1}>
                            {chunk.map(([name, timestamp]) => {
                                const player = providerConfigData.getPlayer(name);
                                const cleanName = player.category === 'Unknown' ? name : player.name;
                                return (
                                    <ListItem alignItems="flex-start" key={name}>
                                        <ListItemAvatar>
                                            <Avatar alt={name} src={providerConfigData.getPlayerImage(cleanName)} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={name}
                                            secondary={
                                                <Box display={'flex'} flexDirection={'column'}>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {formattedTimestamp(timestamp.toDate())}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </ListItem>
                                );
                            })}
                        </Stack>
                    ))}
                </Stack>
            )}
        </div>
    );
};
