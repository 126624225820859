import React, {useEffect, useState} from 'react';
import styles from './RateHighlight.module.css';
import {fetchNextUnratedHighlight, rateHighlight} from "../../../api/domain/highlights_db";
import {Highlight} from "../../../../typings/highlights";

type RateHighlightProps = {
    setShowRate: (show: boolean) => void;
};

export const RateHighlight: React.FC<RateHighlightProps> = ({setShowRate}) => {

    const [highlight, setHighlight] = useState<Highlight>(null);

    useEffect(loadNextHighlight, []);

    function handleRating(rating: number) {
        const id = highlight.id;
        console.log(highlight.id, rating);
        setHighlight(null);
        rateHighlight(id, rating).then(loadNextHighlight);
    }

    function loadNextHighlight() {
        fetchNextUnratedHighlight().then(setHighlight).catch(() => setShowRate(false));
    }

    const clipId = highlight && highlight.url.split('?')[0].split('/').pop();

    return (
        <div className={styles.container}>
            <button onClick={() => setShowRate(false)} className={styles.closeButton}>Close</button>
            {highlight ? (
                <>
                    <div className={styles.highlightContainer}>
                        <iframe
                            src={`https://clips.twitch.tv/embed?clip=${clipId}&parent=localhost&autoplay=true`}
                            height="360"
                            width="640"
                            allowFullScreen>
                        </iframe>
                    </div>
                    <div className={styles.informationContainer}>
                        <p>E-Mail: <b>{highlight.email}</b></p>
                        <p>Timestamp: <b>{new Date(highlight.timestamp.seconds * 1000).toLocaleString()}</b></p>
                    </div>
                    <div className={styles.ratingContainer}>
                        <button onClick={() => handleRating(0)} className={styles.ratingButton}>0</button>
                        <button onClick={() => handleRating(1)} className={styles.ratingButton}>1</button>
                        <button onClick={() => handleRating(2)} className={styles.ratingButton}>2</button>
                        <button onClick={() => handleRating(3)} className={styles.ratingButton}>3</button>
                        <button onClick={() => handleRating(4)} className={styles.ratingButton}>4</button>
                        <button onClick={() => handleRating(5)} className={styles.ratingButton}>5</button>
                    </div>
                </>
            ) : (
                <div className={styles.loadingContainer}>
                Loading...
                </div>
            )}
        </div>
    );
};
