import React, {useState} from 'react';
import {
    Chip,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from '@mui/icons-material/Map';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import {useNavigate} from 'react-router-dom';
import {DRAW_WIDTH} from '../../utils/const';
import {
    CONFIGURATION_ROUTE,
    DASHBOARD_ROUTE,
    HIGHLIGHTS_ROUTE,
    LOGIN_ROUTE,
    MAPS_ROUTE, QUALIFIER_ROUTE,
    RT_DATABASE_ROUTE,
    STUDIO_ROUTE, TEST_DATA_ROUTE
} from '../../router/routerUrls';
import {PeepoLogo} from '../PeepoLogo';
import LogoutIcon from '@mui/icons-material/Logout';
import {getAuth, signOut} from 'firebase/auth';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import {checkUserRole, USER_ROLES} from '../../utils/firebase_consts';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BugReportIcon from '@mui/icons-material/BugReport';
import {useConfig} from '../provider/ConfigProvider';
import * as consts from '../../utils/const';
import {Box} from '@mui/system';

type SidebarProps = {};

const routes = [{
    id: 'dashboard',
    path: DASHBOARD_ROUTE,
    name: 'Dashboard',
    icon: <HomeIcon/>,
    role: USER_ROLES.BANKO,
}, {
    id: 'config',
    path: CONFIGURATION_ROUTE,
    name: 'Configuration',
    icon: <SettingsIcon/>,
    role: USER_ROLES.ADMIN,
}, {
    id: 'maps',
    path: MAPS_ROUTE,
    name: 'Maps',
    icon: <MapIcon/>,
    role: USER_ROLES.BANKO,
}, {
    id: 'qualifier',
    path: QUALIFIER_ROUTE,
    name: 'Qualifier',
    icon: <CardMembershipIcon/>,
    role: USER_ROLES.BANKO,
}, {
    id: 'rtdb',
    path: RT_DATABASE_ROUTE,
    name: 'RTDatabase',
    icon: <LeaderboardIcon/>,
    role: USER_ROLES.BANKO,
}, {
    id: 'studio',
    path: STUDIO_ROUTE,
    name: 'Studio',
    icon: <CameraAltIcon/>,
    role: USER_ROLES.STUDIO,
}, {
    id: 'highlights',
    path: HIGHLIGHTS_ROUTE,
    name: 'Highlights',
    icon: <OndemandVideoIcon/>,
    role: USER_ROLES.MODERATOR,
}, {
    id: 'tests',
    path: TEST_DATA_ROUTE,
    name: 'Test Data',
    icon: <BugReportIcon/>,
    role: USER_ROLES.ADMIN,
}]

export const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
    const navigate = useNavigate();
    const [isSigningOut, setIsSigningOut] = useState(false);
    const peepoSprintConfig = useConfig();
    const logout = async () => {
        if (isSigningOut) {
            return;
        }

        setIsSigningOut(true);

        try {
            const auth = getAuth();
            await signOut(auth);
            navigate(LOGIN_ROUTE);
            console.log('Sign-out successful');
        } catch (error) {
            console.error('An error happened', error);
        } finally {
            setIsSigningOut(false);
        }
    };

    const routesToShow = routes.filter(route => checkUserRole(route.role));

    return (

        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: DRAW_WIDTH,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: DRAW_WIDTH, boxSizing: 'border-box'},
            }}
        >
            <Toolbar sx={{pb: 3, pt: 2}}>
                <Box display={'flex'} position={'relative'} width={'100%'}>
                    <PeepoLogo height={55}/> <p>peepoSprint<br/> {peepoSprintConfig?.eventId || '-'}</p>
                    {consts.isDevMode() && <Box position={'absolute'} bottom={-15} right={-10}><Chip color='error' label="Dev" /></Box>}
                </Box>


            </Toolbar>
            <Divider/>
            <List>
                {routesToShow.map((route) => (
                    <ListItem key={route.id} disablePadding>
                        <ListItemButton
                            onClick={() => !!route.path ? navigate(route.path) : console.warn('Not implemented yet', route)}>
                            <ListItemIcon>
                                {route.icon}
                            </ListItemIcon>
                            <ListItemText primary={route.name}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={logout}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Log out'}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
};
