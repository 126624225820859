// context/AlertContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';
import { Alert as MUIAlert, Snackbar } from '@mui/material';
import {Alert} from '../../types/alerts';
import { v4 as uuidv4 } from 'uuid';

interface AlertContextType {
    addAlert: (message: string, severity: "error" | "warning" | "info" | "success", timeout?: number) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [alerts, setAlerts] = useState<Alert[]>([]);

    const addAlert = useCallback((message: string, severity: "error" | "warning" | "info" | "success", timeout = 3000) => {
        const id = uuidv4();
        setAlerts((prevAlerts) => [...prevAlerts, { id, message, severity, timeout }]);
        setTimeout(() => {
            setAlerts((prevAlerts) => prevAlerts.filter(alert => alert.id !== id));
        }, timeout);
    }, []);

    return (
        <AlertContext.Provider value={{ addAlert }}>
            {children}
            {/* Now rendering alerts directly within the provider */}
            {alerts.map((alert) => (
                <Snackbar key={alert.id} open autoHideDuration={alert.timeout || 6000} onClose={() => setAlerts((prevAlerts) => prevAlerts.filter(a => a.id !== alert.id))}>
                    <MUIAlert severity={alert.severity} sx={{ width: '100%' }}>
                        {alert.message}
                    </MUIAlert>
                </Snackbar>
            ))}
        </AlertContext.Provider>
    );
};
