import React from 'react';
import {Paper, Typography, Button, useTheme} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import styles from './NotFoundPage.module.css';
import {DASHBOARD_ROUTE} from '../../router/routerUrls';

export const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <div className={styles.container} style={{backgroundColor: theme.palette.background.default}}>
            <Paper className={styles.sideBar} style={{color: theme.palette.text.primary}}>
                <Typography variant="h3" component="h1" gutterBottom>
                    404 - Page Not Found
                </Typography>
                <Typography variant="body1" component="p" gutterBottom mt={4} mb={4}>
                    The page you are looking for might have been removed,<br/> had its name changed, or is temporarily
                    unavailable.
                </Typography>
                <Button variant="contained" color="primary" onClick={() => navigate(DASHBOARD_ROUTE)}>
                    Go to Home Page
                </Button>
            </Paper>
        </div>
    );
};
