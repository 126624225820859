import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardHeader, Grid, TextField, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useAlert } from '../../../../components/alert/AlertContext';
import { Logger, LogLevel } from '../../../../utils/Logger';
import { BaseDoc, EventFormat } from 'peepo-types';
import { createBaseDoc } from '../../../../api/domain/base_db';
import styles from './CreateEventForm.module.css';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

type CreateEventFormProps = {
    created: () => void;
};

const logger = new Logger(LogLevel.DEBUG, 'CreateEventForm', true);

const gameModeOptions = {
    SINGLE: 'Single Player',
    TEAM: 'Team Play',
    RELAY_RACE: 'Relay Race'
};

export const CreateEventForm: React.FC<CreateEventFormProps> = ({ created }) => {
    const [form, setForm] = useState<Partial<BaseDoc> & {
        eventId: string,
        gameMode: EventFormat,
        starts: Date | null
    }>({
        eventId: '',
        gameMode: 'TEAM',
        starts: null
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const { addAlert } = useAlert();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (isLoading) {
            logger.info('Form is already submitting');
            return;
        }
        if (validateForm()) {
            setIsLoading(true);
            console.log('Submitting form', form);
            createBaseDoc(form.eventId, form.gameMode as EventFormat, new Date(form.starts))
                .then(() => {
                    addAlert('Form submitted successfully!', 'success');
                    created();
                })
                .catch((error) => {
                    addAlert(`An error occurred: ${error.message}`, 'error', 20000);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            addAlert('Please correct the errors in the form.', 'error');
        }
    };

    useEffect(() => {
        if (errors.title) addAlert(errors.title, 'error');
        // Handle other field errors similarly
    }, [errors, addAlert]);

    const validateForm = () => {
        let tempErrors = {} as any;
        if (!form.eventId?.trim()) {
            tempErrors.eventId = 'EventId is required';
        }
        if (!form.gameMode?.trim()) {
            tempErrors.gameMode = 'GameMode is required';
        }
        if (!form.starts) {
            tempErrors.starts = 'Start date and time are required';
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
        setForm({ ...form, [field]: e.target.value });
    };

    const handleSelectChange = (e: SelectChangeEvent<string>, field: string) => {
        setForm({ ...form, [field]: e.target.value });
    };

    const handleDateChange = (date: Date | null) => {
        setForm({ ...form, starts: date });
    };

    return (
        <Card className={styles.eventForm}>
            <CardHeader title="Create New Event" />
            <CardContent>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="EventId"
                                value={form.eventId}
                                onChange={(e) => handleFormChange(e, 'eventId')}
                                error={!!errors.eventId}
                                helperText={errors.eventId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.gameMode}>
                                <InputLabel>GameMode</InputLabel>
                                <Select
                                    value={form.gameMode}
                                    onChange={(e) => handleSelectChange(e, 'gameMode')}
                                    label="GameMode"
                                >
                                    {Object.entries(gameModeOptions).map(([value, label]) => (
                                        <MenuItem key={value} value={value}>{label}</MenuItem>
                                    ))}
                                </Select>
                                {errors.gameMode && <p style={{ color: 'red' }}>{errors.gameMode}</p>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Starts"
                                    value={form.starts}
                                    onChange={handleDateChange}
                                    disablePast
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
};
