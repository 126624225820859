import {ConfigDocData, MapDocument} from 'peepo-types';
import {Timestamp} from 'firebase/firestore';
import {Timestamp as PeepoStamp} from 'peepo-types';

export const DEFAULT_CONFIG = {
    format: 'RELAY_RACE',
    eventId: 'version10',
    startDate: Timestamp.fromDate(new Date('March 10, 2024 15:00:00 GMT+01:00')) as unknown as PeepoStamp,
    players: [
        //Team 1
        {
            name: 'MultiPorl',
            socials: [{
                type: 'TWITCH',
                url: 'twitch.tv/multiporl'
            }],
            team: 1,
            ingName: 'MultiPori',
            category: 'pro',
        },
        {
            name: 'CastCrafter',
            ingName: 'CastCrafter',
            socials: [{
                type: 'TWITCH',
                url: 'twitch.tv/castcrafter'
            }, {
                type: 'TWITTER',
                url: 'twitter.com/castcrafter'
            }],
            team: 1,
            category: 'medium',
        }],
    teams: [{
        id: 1,
        name: 'Obsidian Outlaws',
        color: '#8a5ffb',
        members: ['MultiPori', 'CastCrafter'],
    },
        {
            id: 2,
            name: 'Emerald Nights',
            color: '#50C878', // Bright green
            members: [],
        },
        {
            id: 3,
            name: 'Lava Legends',
            color: '#FF4500', // Bright orange-red
            members: [],
        },
        {
            id: 4,
            name: 'Netherstorm Raiders',
            color: '#ff7272',
            members: [],
        }]
} as ConfigDocData;


export const mapData = [{
    mapId: 1,
    seed: '474352500291163234',
    queue: {},
    players: {}
}, {
    mapId: 2,
    seed: '340652047383107708',
    queue: {},
    players: {}
}, {
    mapId: 3,
    seed: '82772135929254835',
    queue: {},
    players: {}
}, {
    mapId: 4,
    seed: '2607255150371996085',
    queue: {},
    players: {}
}, {
    mapId: 5,
    seed: '-7442489127281612924',
    queue: {},
    players: {}
}, {
    mapId: 6,
    seed: '29579027377003920',
    queue: {},
    players: {}
}] as MapDocument[];
