.container {
    position: relative;
    width: 350px;
    padding: 35px 40px;
    display: flex;
    flex-direction: column;
}

.started {
    background-color: #2b2a2a;
    border: #713028 solid 1px;
}

.active {
    border: #4e734e solid 1px;
}


.statContainer {
    width: 100%;
    margin-top: 20px;
}

.buttonContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}



.switchPosition {
    position: absolute;
    top: 10px;
    right: 20px;
}
