import React from 'react';
import {Paper, Typography, Box, Divider, List, ListItem, ListItemText, Chip} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import styles from './EventCard.module.css';
import {BaseDoc} from 'peepo-types';
import {useConfig} from '../../../components/provider/ConfigProvider';

type EventCardProps = {
    data: BaseDoc
};

export const EventCard: React.FC<EventCardProps> = ({ data }) => {
    const { eventId = 'Not set', createdAt } = data;
    let active = false;
    let config = data.config;
    const peepoSprintConfig = useConfig();
    if(peepoSprintConfig.eventId === eventId) {
        config = peepoSprintConfig;
        active = true;
    }
    if(!config) {
        return <Paper className={styles.container} elevation={3}>
            <Box display="flex" alignItems="center" p={2} flexDirection={'column'}>
                    <Typography variant="h6">{eventId}</Typography>
                    <Typography variant="body2" color="textSecondary">GameMode: -</Typography>
                {active && <Typography variant="body2" color="textSecondary">Active</Typography>}
            </Box>
            <Divider />
            <Box p={2}>
                <Typography variant="body1">Players: no config</Typography>
                <Typography variant="body1">Teams: no config</Typography>
                <Typography variant="body2">Starts: no config</Typography>
                <Typography variant="body2">Created: {createdAt ? createdAt.toDate().toDateString() : '?'}</Typography>
            </Box>
        </Paper>
    }
    const { format, startDate, teams, players } = config;
    return (
        <Paper className={styles.container} elevation={3}>
            <Box display="flex" alignItems="center" p={2} flexDirection={'column'} position={'relative'}>
                    <Typography variant="h6">{eventId}</Typography>
                    <Typography variant="body2" color="textSecondary">GameMode: {format}</Typography>

                {active && <Box position={'absolute'} top={10} right={10}>    <Chip
                    icon={<NotificationsIcon />}
                    label="Active"
                    color="success"
                /></Box>}
            </Box>
            <Divider />
            <Box height={'100%'} p={2}>
                <Typography variant="body1">Teams:</Typography>
                <List dense>
                    {teams.map((team, index) => (
                        <ListItem key={team.id}>
                            <ListItemText
                                primary={team.name}
                                secondary={team.members?.join(', ')}
                            />
                        </ListItem>
                    ))}
                </List>
                <Box mt={'auto'}>
                    <Typography variant="body2">Starts: {startDate ? startDate.toDate().toDateString() : '?'}</Typography>
                    <Typography variant="body2">Created: {createdAt ? createdAt.toDate().toDateString() : '?'}</Typography>
                </Box>

            </Box>
        </Paper>
    );
};
