import React, {useEffect, useState} from 'react';
import styles from './HighlightList.module.css';
import {Highlight} from "../../../../typings/highlights";
import {fetchRatedHighlights} from "../../../api/domain/highlights_db";

type HighlightListProps = {
    highlightCount: number;
};

export const HighlightList: React.FC<HighlightListProps> = ({highlightCount}) => {
    const [highlights, setHighlights] =
        useState<Highlight[] | undefined>(undefined);

    function loadHighlights() {
        setHighlights(undefined);
        fetchRatedHighlights(highlightCount).then(setHighlights);
    }

    useEffect(loadHighlights, [highlightCount]);

    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <thead>
                <tr>
                    <th>Rating</th>
                    <th>Timestamp</th>
                    <th>Clip</th>
                </tr>
                </thead>
                <tbody>
                {highlights ? highlights.map((highlight) => (
                    <tr key={highlight.id}>
                        <td>
                            <span className={styles.ratingStars}>{[...Array(highlight.rating)].map(() => "⭐️")}</span>
                            <span>({highlight.rating})</span>
                        </td>
                        <td>{new Date(highlight.timestamp.seconds * 1000).toLocaleString()}</td>
                        <td>
                            <a href={highlight.url} target="_blank" rel="noreferrer"
                               className={styles.clipLink}>
                                Clip
                            </a>
                        </td>
                    </tr>
                )) : (
                    <tr>
                        <td colSpan={3}>Loading...</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};
