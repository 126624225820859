.container {
    min-height: 20vh;
}


.seedContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 40px;
    flex-direction: row;
}

