import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAlert } from '../../../components/alert/AlertContext';
import { setMapDocument } from '../../../api/domain/map_db';
import { map1Data } from './data/map_1';
import { map2Data } from './data/map_2';
import { map3Data } from './data/map_3';
import {map4Data} from './data/map_4';
import {map5Data} from './data/map_5';

type InsertMapProperties = {};

export const InsertTestDataButton: React.FC<InsertMapProperties> = ({ }) => {
    const { addAlert } = useAlert();
    const [creating, setCreating] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        setCreating(true);
        handleClose();
        try {
            await setMapDocument(1, map1Data); // Adjust the method as needed
            await setMapDocument(2, map2Data); // Adjust the method as needed
            await setMapDocument(3, map3Data); // Adjust the method as needed
            await setMapDocument(4, map4Data); // Adjust the method as needed
            await setMapDocument(5, map5Data); // Adjust the method as needed
            addAlert(`Map documents have been successfully set`, "success");
        } catch (error) {
            console.error("Failed to create map:", error);
            addAlert("Failed to create map. Please try again.", "error");
        } finally {
            setCreating(false);
        }
    };

    return (
        <>
            <Button variant="outlined" disabled={creating} onClick={handleClickOpen}>
                {creating ? `Setting Maps...` : `Set Map Documents`}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Action"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to set the map documents? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
