.paper {
    width: 400px;
    padding: 15px 15px;
    margin: 16px 0;
}

.playerName {
    margin-bottom: 40px;
    font-size: 2rem;
    text-transform: none;
}

.fieldContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
}

.inputField {
    width: 100%;
}

.deleteButton {
    float: left;
    margin-right: auto;
}


.selector {
   float: right;
    margin-left: auto;
}
