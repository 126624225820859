import * as React from 'react';
import { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { QualifierPlayerData } from '../../../types/qualifier';
import { addQualifier } from '../../../firebase/firebase';
import {fetchMcsrPlayerData} from './qualifier_service';
import {useConfig} from '../../../components/provider/ConfigProvider';
import {useAlert} from '../../../components/alert/AlertContext';

type Props = {};

export const QualifierForm: React.FC<Props> = () => {
    const [playerName, setPlayerName] = useState('');
    const [email, setEmail] = useState('');
    const [identifier, setIdentifier] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const providerConfigData = useConfig();
    const alerts = useAlert();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage(''); // Reset error message

        // Check if identifier exists
        const response = await fetchMcsrPlayerData(identifier);
        if (!response) {
            setErrorMessage('Could not find player with that identifier. Please check and try again.');
            return;
        }

        const newQualifier: QualifierPlayerData = {
            playerName,
            email,
            identifier,
            faulty: false
        };

        try {
            await addQualifier(providerConfigData.eventId, newQualifier);
            alerts.addAlert(`Added ${newQualifier.playerName} to the qualifier.`, 'success');
            setPlayerName('');
            setEmail('');
            setIdentifier('');

            // Optionally, add a notification or alert here to inform the user of the successful submission.
        } catch (error) {
            console.error('Error adding qualifier: ', error);
            setErrorMessage('Error adding qualifier. Please try again.');
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '300px', margin: '0 auto', padding: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
            <Typography variant="h6" align="center">Add Qualifier</Typography>
            <TextField
                label="Player Name"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                required
            />
            <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <TextField
                label="Identifier"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                required
                error={!!errorMessage}
                helperText={errorMessage}
            />
            <Button type="submit" variant="contained" color="primary">
                Submit
            </Button>
            <Button type="submit" variant="outlined" color="primary">
                Submit
            </Button>
        </Box>
    );
};
