"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractLogger = exports.LogLevel = void 0;
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["ERROR"] = 0] = "ERROR";
    LogLevel[LogLevel["WARN"] = 1] = "WARN";
    LogLevel[LogLevel["INFO"] = 2] = "INFO";
    LogLevel[LogLevel["SUCCESS"] = -1] = "SUCCESS";
    LogLevel[LogLevel["DEBUG"] = 4] = "DEBUG";
    LogLevel[LogLevel["DEBUG_ADV"] = 5] = "DEBUG_ADV";
})(LogLevel || (exports.LogLevel = LogLevel = {}));
class AbstractLogger {
    constructor(level = LogLevel.INFO, tag = 'Logger', enable = false, useColor = true) {
        this.level = level;
        this.tag = tag;
        this.enable = enable;
        this.useColor = useColor;
    }
    prettyPrint(level, message, tag) {
        const colors = {
            [LogLevel.ERROR]: '\x1b[31m', // Red
            [LogLevel.WARN]: '\x1b[33m', // Yellow
            [LogLevel.INFO]: '', // White
            [LogLevel.DEBUG]: '\x1b[90m', // Gray
            [LogLevel.SUCCESS]: '\x1b[32m', // Green
            [LogLevel.DEBUG_ADV]: '\x1b[90m', // Gray
        };
        if (!this.useColor) {
            return message;
        }
        const timestamp = new Date().toISOString().replace('T', ' ').substring(0, 19); // Format as 'YYYY-MM-DD HH:mm:ss'
        return `[${timestamp} ${tag} ${LogLevel[level]}]${colors[level]} ${message}\x1b[0m`;
    }
    info(message, ...args) {
        if (this.enable && this.level >= LogLevel.INFO) {
            this.logInfo(this.prettyPrint(LogLevel.INFO, message, this.tag), ...args);
        }
    }
    debug(message, ...args) {
        if (this.enable && this.level >= LogLevel.DEBUG) {
            this.logDebug(this.prettyPrint(LogLevel.DEBUG, message, this.tag), ...args);
        }
    }
    warn(message, ...args) {
        if (this.enable && this.level >= LogLevel.WARN) {
            this.logWarn(this.prettyPrint(LogLevel.WARN, message, this.tag), ...args);
        }
    }
    error(message, ...args) {
        if (this.enable && this.level >= LogLevel.ERROR) {
            this.logError(this.prettyPrint(LogLevel.ERROR, message, this.tag), ...args);
        }
    }
}
exports.AbstractLogger = AbstractLogger;
