import * as React from 'react';
import {Box, CssBaseline} from '@mui/material';
import {Sidebar} from './sidebar/Sidebar';
import {useLocation} from 'react-router-dom';
import {LOGIN_ROUTE, NOT_FOUND_ROUTE} from '../router/routerUrls';
import {PageHeader} from './PageHeader';


type Props = {
    children?: React.ReactNode;
};

const hideSidebarPages = [LOGIN_ROUTE, NOT_FOUND_ROUTE];

export const BaseContainer = (props: Props) => {
    const location = useLocation();
    // Hide sidebar on login page
    if(hideSidebarPages.includes(location.pathname)) return <>{props.children}</>;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Sidebar/>

            <Box
                component="main"
                sx={{flexGrow: 1, bgcolor: 'background.default', pl: 3}}
            >
                <Box maxWidth={1400}>
                    <PageHeader />
                    {props.children}
                </Box>

            </Box>
        </Box>
    );
};
