import React from 'react';
import {Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import styles from './SocialInput.module.css';
import CloseIcon from '@mui/icons-material/Close';
import {Social} from 'peepo-types';

type SocialInputProps = {
    social: Social;
    index: number;
    onSocialChange: (index: number, newSocial: Social) => void;
    onRemove: (index: number) => void;
};

export const SocialInput: React.FC<SocialInputProps> = ({social, index, onSocialChange, onRemove}) => {
    const handleChange = (field: string, value: any) => {
        const updatedSocial = {...social, [field]: value};
        onSocialChange(index, updatedSocial);
    };

    return (
        <Grid container mt={1} display={'flex'} alignItems={'center'}>
            <Grid item md={12} mb={2}>
                <Divider />
            </Grid>

            <Grid item  md={4}>
                <FormControl variant="outlined" className={styles.inputField}>
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={social.type}
                        onChange={(e) => handleChange('type', e.target.value)}
                        label="Type"
                    >
                        <MenuItem value="TWITCH">Twitch</MenuItem>
                        <MenuItem value="TWITTER">Twitter</MenuItem>
                        <MenuItem value="YOUTUBE">YouTube</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={6}>
                <TextField
                    label="URL"
                    variant="outlined"
                    value={social.url}
                    onChange={(e) => handleChange('url', e.target.value)}
                    className={styles.inputField}
                />
            </Grid>
            <Grid item md={2}>
                <Button aria-label="delete" color="error" onClick={() => onRemove(index)}>
                    <CloseIcon/>
                </Button>
            </Grid>
        </Grid>
    );
};
