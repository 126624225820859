import React, {MouseEvent, useState} from 'react';
import styles from './StudioControl.module.css';
import {Button} from '@mui/material';
import {updateCurrentLeaderboard} from '../../../api/domain/base_db';
import {useConfig} from "../../../components/provider/ConfigProvider";

type StudioControlProps = {
    currentLeaderboard: number;
};

export const StudioControl: React.FC<StudioControlProps> = () => {
    const {currentLeaderboard} = useConfig();

    const [loading, setLoading] = useState(false);
    const currentLeaderboardNumber = Number(currentLeaderboard);

    const handleMapSwitch = async (id: number) => {
        setLoading(true);
        await updateCurrentLeaderboard(String(id))
        setLoading(false);
    }

    return (
        <div className={styles.container}>
            <div className={styles.seedContainer}>

            </div>
            <div className={styles.buttonContainer}>
                {[1, 2, 3, 4, 5].map((id) => {
                    return <Button variant={id === currentLeaderboardNumber ? 'outlined' : 'contained'}
                                   onClick={() => handleMapSwitch(id)}
                                   disabled={id === currentLeaderboardNumber || loading}>{id}</Button>
                })}
            </div>
        </div>
    );
};
