import React, {useState} from 'react';
import styles from './QualifierPage.module.css';
import {useAlert} from '../../../components/alert/AlertContext';
import {QualifierList} from './QualifierList';
import {QualifierForm} from './QualifierForm';
import {Button, Divider} from '@mui/material';
import * as firebase from '../../../firebase/firebase';
import {Box} from '@mui/system';
import {Grid} from '@mui/material';
import {QualifierLeaderboard} from './QualifierLeaderboard';
import {AddTestMembersButton} from './AddTestMembersButton';


type QualifierPageProps = {};

export const QualifierPage: React.FC<QualifierPageProps> = (props: QualifierPageProps) => {
    const alerts = useAlert();
    const [loading, setLoading] = useState(false);

    const updateLeaderboard = async () => {
        setLoading(true);
        try {
            await firebase.callUpdateLeaderboard();
            alerts.addAlert('Leaderboard updated successfully', 'success');
        } catch (error) {
            alerts.addAlert('Failed to update leaderboard', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <div>

                <Grid container spacing={2} mt={2}>
                    <Grid item md={4} xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <AddTestMembersButton />
                        <Box mt={2} mb={2} width={'100%'}>
                            <Divider />
                        </Box>
                        <QualifierForm/>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <QualifierLeaderboard/>

                    </Grid>
                </Grid>

                <Box mt={10}>

                    <Box display={'flex'} justifyContent={'space-between'} mt={1} alignItems={'baseline'}>
                        <div>
                            <h3>Qualifier Leaderboard</h3>
                            <p>Leaderboard is updated every 4 hours.</p>
                        </div>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={updateLeaderboard}
                                disabled={loading}
                            >
                                {loading ? 'Updating...' : 'Update Leaderboard'}
                            </Button>
                        </Box>
                    </Box>

                    <Box mt={5} mb={10}>
                        <QualifierList/>
                    </Box>
                </Box>


            </div>
        </div>
    );
};
