import * as React from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {Breadcrumbs, Link, Typography} from '@mui/material';
import {LOGIN_ROUTE} from '../router/routerUrls';

type PageHeaderProps = {};


export const PageHeader = (props: PageHeaderProps) => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment);

    if (location.pathname === LOGIN_ROUTE) {
        return <></>;
    }

    return (
        <div>
            <h1>{pathSegments[pathSegments.length - 1]}</h1>
            <Breadcrumbs aria-label="breadcrumb">
                {pathSegments.map((segment, index) => {
                    const path = `/${pathSegments.slice(0, index + 1).join('/')}`;

                    if (index === pathSegments.length - 1) {
                        return <Typography color="gray" key={index}>{index === 0 ? '» ' : ''}{segment}</Typography>;
                    }

                    return (
                        <Link component={RouterLink} to={path} key={index}>
                            <Typography color="gray" key={index}>
                                » {segment}
                            </Typography>
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </div>
    );
};
