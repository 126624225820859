
import {fetchConfig} from '../firebase/firebase';
import {ConfigDocData, EventFormat, Player, Team} from 'peepo-types';
import {Timestamp} from 'firebase/firestore';


export class PeepoSprintConfigData {
    private static instance: PeepoSprintConfigData;
    public eventId: string;
    public players: Player[];
    public teams: Team[];
    public format: EventFormat;
    public startDate: Timestamp;

    public constructor() {
        // Set default values
        this.eventId = 'version11';
        this.players = [];
        this.teams = [];
        this.format = 'SINGLE';
        this.startDate = Timestamp.now();
    }


    public static async getInstance(): Promise<PeepoSprintConfigData> {
        if (!PeepoSprintConfigData.instance) {
            PeepoSprintConfigData.instance = new PeepoSprintConfigData();
            await PeepoSprintConfigData.instance.loadConfigFromFirebase();
        }
        return PeepoSprintConfigData.instance;
    }

    public async loadConfigFromFirebase() {
        const config = await fetchConfig();
        if (config) {
            this.eventId = config.eventId;
            this.players = config.players;
            this.teams = config.teams;
            this.format = config.format;
            // @ts-ignore
            this.startDate = config.startDate; // TODO change me  config.startDate ||
        } else {
            throw new Error('Could not find a config document in Firebase');
        }
    }


    public getPlayerImage(name: string) {
        return `https://peeposprint.at/assets/member/${name.toLowerCase()}.png`;
    }

    public getTeam(ingName: string) {
        let find = this.teams.find(team => team.members.includes(ingName));
        if(!find) {
            return {
                id: 0,
                name: 'Unknown',
                color: '#808080',
                members: [ingName, 'Unknown']
            }
        }
        return find;
    }

    public getTeamById(id: number) {
        let find = this.teams.find(team => team.id === id);
        if(!find) {
            return {
                id: 0,
                name: 'Unknown',
                color: '#808080',
                members: ['Unknown', 'Unknown']
            }
        }
        return find;
    }


    public getPlayer(ingName: string) {
        let find = this.players.find(team => team.ingName === ingName);
        if(!find) {
            return {
                name: 'Unknown',
                ingName: 'Unknown',
                socials: ['Unknown', 'Unknown'],
                team: 0,
                category: 'Unknown'
            }
        }
        return find;
    }


}
