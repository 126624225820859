import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useAlert } from '../../../components/alert/AlertContext';
import {createMapDocument} from '../../../api/domain/map_db';

type CreateMapButtonProps = {
    mapId: number;
    onMapCreated: () => void;
};

export const CreateMapButton: React.FC<CreateMapButtonProps> = ({ mapId, onMapCreated }) => {
    const { addAlert } = useAlert();
    const [creating, setCreating] = useState(false);

    const handleClick = async () => {
        setCreating(true);
        try {
            await createMapDocument(mapId);
            addAlert(`Map ${mapId} created successfully!`, "success");
            onMapCreated();
        } catch (error) {
            console.error("Failed to create map:", error);
            addAlert("Failed to create map. Please try again.", "error");
        } finally {
            setCreating(false);
        }
    };

    return (
        <Button variant={'outlined'} disabled={creating} onClick={handleClick}>
            {creating ? `Creating Map ${mapId}...` : `Create Map ${mapId}`}
        </Button>
    );
};
