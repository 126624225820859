import React, { useState } from 'react';
import { Button } from '@mui/material';
import styles from './TeamConfigEditor.module.css';
import { TeamCard } from './TeamCard';
import Box from '@mui/material/Box';
import { useConfigEditor } from '../form/ConfigEditProvider';

type TeamConfigEditorProps = {};

export const TeamConfigEditor: React.FC<TeamConfigEditorProps> = ({}) => {
    const { data, setData } = useConfigEditor();
    const { teams } = data;

    const addTeam = () => {
        const newTeam = { id: data.teams.length + 1, name: '', color: '', members: [] }; // Adjust the default values as necessary
        setData({ ...data, teams: [...data.teams, newTeam] });
    };

    const removeTeam = (teamId: number) => {
        const updatedTeams = data.teams.filter(team => team.id !== teamId);
        setData({ ...data, teams: updatedTeams });
    };

    return (
        <div className={styles.container}>
            <h4>Team Configuration</h4>
            <div className={styles.cardContainer}>
                {teams && teams.map(team => (
                    <TeamCard key={team.id} data={team} onRemove={removeTeam}/>
                ))}
            </div>

            <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                <Button variant={'contained'} onClick={addTeam}>Add Team</Button>
            </Box>
        </div>
    );
};
