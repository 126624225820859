import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAlert } from '../../../components/alert/AlertContext';
import {PeepoRestClient} from '../../../api/PeepoRestClient';

type InsertMapProperties = {};

export const CalculateLeaderboardButton: React.FC<InsertMapProperties> = ({ }) => {
    const { addAlert } = useAlert();
    const [creating, setCreating] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        setCreating(true);
        handleClose();
        try {
            const response = await PeepoRestClient.calculateLeaderboards();
            addAlert(response.message || `Leaderboard calculation started`, "success");
        } catch (error) {
            console.error("Failed to calculate leaderboard:", error);
            addAlert("Failed to calculate leaderboard. Please try again.", "error");
        } finally {
            setCreating(false);
        }
    };

    return (
        <>
            <Button variant="outlined" disabled={creating} onClick={handleClickOpen}>
                {creating ? `Calculating Leaderboard...` : `Calculate Leaderboard`}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Action"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to calculate the leaderboard? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
