// @ts-nocheck
import {Timestamp} from 'firebase/firestore';
import {MapDocument} from 'peepo-types';

export const map4Data: MapDocument = {
    mapId: 4,
    seed: "255872837998042366",
    start: Timestamp.now(),  // Current timestamp
    players: {
        "dimowo": {
            runId: "704330339",
            checkpoint: "FINISHED",
            ticks: 31753,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 0
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 235.5,
                DEATHS: 3,
                END_IN: 29476,
                FINISHED: 31753,
                NETHER_IN: 4014
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Chaosflo44": {
            runId: "590841483",
            checkpoint: "FINISHED",
            ticks: 30702,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 0,
                ENDER_PERL: 2
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1208,
                DEATHS: 2,
                END_IN: 29489,
                FINISHED: 30702,
                NETHER_IN: 3550
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Dimeax": {
            runId: "103516484",
            checkpoint: "FINISHED",
            ticks: 17911,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 1
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1470,
                END_IN: 16749,
                FINISHED: 17911,
                NETHER_IN: 2844
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "MultiPori": {
            runId: "1668170717",
            checkpoint: "FINISHED",
            ticks: 19243,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 6,
                ENDER_PERL: 28
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 139,
                END_IN: 18114,
                FINISHED: 19243,
                NETHER_IN: 3544
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "TheFabo": {
            runId: "315730941",
            checkpoint: "FINISHED",
            ticks: 12515,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 6
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 101,
                END_IN: 10861,
                FINISHED: 12515,
                NETHER_IN: 3454
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Wichtiger": {
            runId: "389478587",
            checkpoint: "FINISHED",
            ticks: 25701,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 196.25,
                ENDER_EYE: 2,
                ENDER_PERL: 7
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 6582.5,
                DEATHS: 3,
                END_IN: 12793,
                FINISHED: 25701,
                NETHER_IN: 3369
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "mcsr_misfit": {
            runId: "1548737363",
            checkpoint: "FINISHED",
            ticks: 17697,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 6,
                ENDER_PERL: 7
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 4596,
                DEATHS: 3,
                END_IN: 16568,
                FINISHED: 17697,
                NETHER_IN: 3017
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "noooreax": {
            runId: "1909643426",
            checkpoint: "FINISHED",
            ticks: 14325,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 1,
                ENDER_PERL: 10
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 2151.5,
                END_IN: 12697,
                FINISHED: 14325,
                NETHER_IN: 3841
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Faister": {
            runId: "111223344",
            checkpoint: "FINISHED",
            ticks: 15678,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 12
            },
            progressPoints: 60,
            stats: {
                DAMAGE_TAKEN: 1350,
                END_IN: 14500,
                FINISHED: 15678,
                NETHER_IN: 3500
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Papaplatte": {
            runId: "222334455",
            checkpoint: "FINISHED",
            ticks: 17222,
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 5,
                ENDER_PERL: 20
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1450,
                END_IN: 15500,
                FINISHED: 17222,
                NETHER_IN: 3200
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Finnitz": {
            runId: "333445566",
            checkpoint: "END_IN",
            ticks: 19000,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 4,
                ENDER_PERL: 15
            },
            progressPoints: 58,
            stats: {
                DAMAGE_TAKEN: 1400,
                END_IN: 17000,
                FINISHED: 0,
                NETHER_IN: 3000
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Nentox": {
            runId: "444556677",
            checkpoint: "FINISHED",
            ticks: 16789,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 6,
                ENDER_PERL: 22
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1500,
                END_IN: 15500,
                FINISHED: 16789,
                NETHER_IN: 3400
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        }
    },
    queue: {
        "CastCrafter": Timestamp.now(),
        "Chaosflo44": Timestamp.now(),
        "Dimeax": Timestamp.now(),
        "MultiPori": Timestamp.now(),
        "mcsr_misfit": Timestamp.now(),
        "Finnitz": Timestamp.now()
        // One player from each team
    }
};
