// @ts-nocheck
import {Timestamp} from 'firebase/firestore';
import {MapDocument} from 'peepo-types';

export const map5Data: MapDocument = {
    mapId: 5,
    seed: "4100897809495913321",
    start: Timestamp.now(),  // Current timestamp
    players: {
        "dimowo": {
            runId: "506914406",
            checkpoint: "END_IN",
            ticks: 16880,
            progress: {
                BLAZE_ROD: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 11
            },
            progressPoints: 55,
            stats: {
                DAMAGE_TAKEN: 3760,
                DEATHS: 1,
                END_IN: 16880,
                NETHER_IN: 4048
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Chaosflo44": {
            runId: "766498156",
            checkpoint: "FINISHED",
            ticks: 15311,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 5,
                ENDER_PERL: 4
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 169,
                END_IN: 13752,
                FINISHED: 15311,
                NETHER_IN: 3647
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Dimeax": {
            runId: "954608192",
            checkpoint: "FINISHED",
            ticks: 13266,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 12
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 2177,
                END_IN: 12165,
                FINISHED: 13266,
                NETHER_IN: 3584
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "MultiPori": {
            runId: "760040114",
            checkpoint: "FINISHED",
            ticks: 12559,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 5,
                ENDER_PERL: 15
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 586,
                END_IN: 11561,
                FINISHED: 12559,
                NETHER_IN: 2623
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "TheFabo": {
            runId: "1408676630",
            checkpoint: "FINISHED",
            ticks: 20871,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 32
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 130.5,
                DEATHS: 3,
                END_IN: 9141,
                FINISHED: 20871,
                NETHER_IN: 2783
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Wichtiger": {
            runId: "67271617",
            checkpoint: "FINISHED",
            ticks: 14315,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 5,
                ENDER_PERL: 4
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 2961,
                END_IN: 12992,
                FINISHED: 14315,
                NETHER_IN: 3730
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "mcsr_misfit": {
            runId: "193834312",
            checkpoint: "FINISHED",
            ticks: 11588,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 4,
                ENDER_PERL: 14
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1414.5,
                DEATHS: 3,
                END_IN: 10619,
                FINISHED: 11588,
                NETHER_IN: 1969
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "noooreax": {
            runId: "209433644",
            checkpoint: "NETHER_IN",
            ticks: 4212,
            progress: {
                BLAZE_ROD: 0,
                ENDER_EYE: 0,
                ENDER_PERL: 0
            },
            progressPoints: 32,
            stats: {
                DAMAGE_TAKEN: 972,
                DEATHS: 2,
                NETHER_IN: 4212
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Faister": {
            runId: "111223344",
            checkpoint: "FINISHED",
            ticks: 15678,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 12
            },
            progressPoints: 60,
            stats: {
                DAMAGE_TAKEN: 1350,
                END_IN: 14500,
                FINISHED: 15678,
                NETHER_IN: 3500
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Papaplatte": {
            runId: "222334455",
            checkpoint: "FINISHED",
            ticks: 17222,
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 5,
                ENDER_PERL: 20
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1450,
                END_IN: 15500,
                FINISHED: 17222,
                NETHER_IN: 3200
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Finnitz": {
            runId: "333445566",
            checkpoint: "END_IN",
            ticks: 19000,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 4,
                ENDER_PERL: 15
            },
            progressPoints: 58,
            stats: {
                DAMAGE_TAKEN: 1400,
                END_IN: 17000,
                FINISHED: 0,
                NETHER_IN: 3000
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Nentox": {
            runId: "444556677",
            checkpoint: "FINISHED",
            ticks: 16789,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 6,
                ENDER_PERL: 22
            },
            progressPoints: 64,
            stats: {
                DAMAGE_TAKEN: 1500,
                END_IN: 15500,
                FINISHED: 16789,
                NETHER_IN: 3400
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        }
    },
    queue: {
        "Dimeax": Timestamp.now(),
        "MultiPori": Timestamp.now(),
        "mcsr_misfit": Timestamp.now(),
        "Nentox": Timestamp.now(),
        "Finnitz": Timestamp.now(),
        "dimowo": Timestamp.now()
        // One player from each team
    }
};
