import {
    collection,
    query,
    where,
    onSnapshot,
    getFirestore,
    orderBy,
    limit,
    getDocs,
    doc,
    getDoc,
    updateDoc,
    startAfter,
    setDoc
} from 'firebase/firestore';
import {MapDocument, MapLogEntry} from 'peepo-types';
import {getBaseDocPath} from '../../firebase/firebase';
import {PeepoSprintConfigData} from '../../utils/PeepoSprintConfigData';
import {PEEPOSPRINT_COLLECTION} from '../../../peepo-types/src/firestore/collection_const';
import {CONFIG_DOC, MAPS_COLLECTION} from 'peepo-types/firestore/collection_const';


export async function setMapDocument(id: number, data: MapDocument) {
    const db = getFirestore();
    const configInstance = await PeepoSprintConfigData.getInstance();
    const baseDocRef = getBaseDocPath(configInstance.eventId);
    try {
        // Create a new document in the "maps" collection
        await setDoc(doc(db, `${baseDocRef}/maps/map_${id}`), data);
        console.log(`MapDocument with id ${id} created.`);
    } catch (error) {
        console.error('Error creating map document: ', error);
        throw error; // Rethrow the error for further handling
    }
}

export async function createMapDocument(id: number) {
    const db = getFirestore();
    const configInstance = await PeepoSprintConfigData.getInstance();
    const baseDocRef = getBaseDocPath(configInstance.eventId);
    const baseDoc = {
        mapId: id,
        seed: null,
        players: {},
        queue: {},
        boostedPlayers: [],
        positions: {}
    }
    try {
        // Create a new document in the "maps" collection
        await setDoc(doc(db, `${baseDocRef}/maps/map_${id}`), baseDoc);
        console.log(`MapDocument with id ${id} created.`);
    } catch (error) {
        console.error('Error creating map document: ', error);
        throw error; // Rethrow the error for further handling
    }
}


export async function getPlayerLogsSubscription(seed: string, playerName: string, callback: (logs: MapLogEntry[]) => void) {
    const db = getFirestore();
    const configInstance = await PeepoSprintConfigData.getInstance();
    const baseDocRef = getBaseDocPath(configInstance.eventId);
    const logsCollectionPath = `${baseDocRef}/maps/seed_${seed}/logs`;

    // Create a query
    const q = query(
        collection(db, logsCollectionPath),
        where('playerName', '==', playerName),
        orderBy('createdAt'),
        limit(20)
    );

    // Set up the subscription
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const logs: MapLogEntry[] = [];
        querySnapshot.forEach((doc) => {
            logs.push(doc.data() as MapLogEntry);
        });
        console.log('Logs for player: ', logs);
        callback(logs);
    });

    // Return the unsubscribe function so it can be called to stop listening to updates
    return unsubscribe;
}

export async function getPlayerLogs(seed: string | undefined, playerName: string | undefined, lastVisible = null) {
    if (!seed || !playerName) {
        throw new Error('Seed and playerName must be provided');
    }
    const db = getFirestore();
    const configInstance = await PeepoSprintConfigData.getInstance();
    const baseDocRef = getBaseDocPath(configInstance.eventId);
    const logsCollectionPath = `${baseDocRef}/maps/seed_${seed}/logs`;

    // Create a query
    let q = query(
        collection(db, logsCollectionPath),
        where('playerName', '==', playerName),
        orderBy('createdAt'),
        limit(20)
    );

    // If lastVisible is not null, start after the last visible document
    if (lastVisible) {
        q = query(q, startAfter(lastVisible));
    }

    try {
        // Fetch the documents
        const querySnapshot = await getDocs(q);
        const logs: MapLogEntry[] = [];
        let lastVisibleDocument = null;

        querySnapshot.forEach((doc) => {
            logs.push(doc.data() as MapLogEntry);
            lastVisibleDocument = doc; // Keep track of the last document
        });

        console.log('Logs for player: ', logs);
        return {logs, lastVisible: lastVisibleDocument}; // Return the logs and the lastVisible document
    } catch (error) {
        console.error('Error fetching player logs: ', error);
        throw error; // Rethrow the error for further handling
    }
}

export async function getMapDocument(seed: string) {
    const db = getFirestore();

    try {
        const configInstance = await PeepoSprintConfigData.getInstance();
        const baseDocRef = getBaseDocPath(configInstance.eventId);
        const docRef = doc(db, baseDocRef, 'maps', `seed_${seed}`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            console.log('Map Document data:', docSnapshot.data());
            return docSnapshot.data() as MapDocument; // Return the document data
        } else {
            console.log('No such map document!');
            return null; // Return null or handle as needed
        }
    } catch (error) {
        console.error('Error fetching map document: ', error);
        throw error; // Rethrow the error for further handling
    }
}

export const listenMapDocument = async (mapId: number, callback: (data: MapDocument | undefined) => void) => {
    const configInstance = await PeepoSprintConfigData.getInstance();
    const baseDocRef = getBaseDocPath(configInstance.eventId);
    const mapDocRef = doc(getFirestore(), baseDocRef, 'maps', `map_${mapId}`);

    return onSnapshot(mapDocRef, (docSnap) => {
        console.log("Document updated with ID: ", docSnap.id);
        callback(docSnap.data() as MapDocument);
    }, (error) => {
        console.error("Error fetching map document: ", error);
    });
}

export async function setSeed(mapId: number, seed: string) {
    const configInstance = await PeepoSprintConfigData.getInstance();
    const docPath = getBaseDocPath(configInstance.eventId);
    const map = doc(getFirestore(), docPath, MAPS_COLLECTION, `map_${mapId}`);

    try {
        await updateDoc(map, {
            seed: seed
        });
        console.log(`Set seed of ${mapId} to ${seed}!`);
    } catch (error) {
        console.error(`Error updating seed of ${mapId}: `, error);
        throw error; // Rethrow the error for further handling
    }
}

export async function setActiveMap(mapId: number) {
    const configDocRef = doc(getFirestore(), PEEPOSPRINT_COLLECTION, CONFIG_DOC);

    try {
        await updateDoc(configDocRef, {
            currentMap: mapId
        });
        console.log(`Active map set to ${mapId}!`);
    } catch (error) {
        console.error('Error updating active map: ', error);
        throw error; // Rethrow the error for further handling
    }
}


export async function getMaps() {
    try {
        // Fetch all documents from maps collection
        const configInstance = await PeepoSprintConfigData.getInstance();
        const docPath = getBaseDocPath(configInstance.eventId);
        const querySnapshot = await getDocs(collection(getFirestore(), docPath, MAPS_COLLECTION));
        const maps: MapDocument[] = [];
        querySnapshot.forEach((doc) => {
            maps.push(doc.data() as MapDocument);
        });

        return maps; // Return the maps
    } catch (error) {
        console.error('Error fetching maps: ', error);
        throw error; // Rethrow the error for further handling
    }
}


export async function creteMapDocuments(mapData: any[]) {
    const db = getFirestore();
    const configInstance = await PeepoSprintConfigData.getInstance();
    const docPath = getBaseDocPath(configInstance.eventId);

    try {
        // Create a new document in the "maps" collection
        for (const data of mapData) {
            await setDoc(doc(db, `${docPath}/maps/seed_${data.seed}`), data, {merge: true});
        }
        console.log(`MapDocuments created.`);
    } catch (error) {
        console.error('Error creating map documents: ', error);
        throw error; // Rethrow the error for further handling
    }

}

export async function resetStart(mapId: number) {
    const configInstance = await PeepoSprintConfigData.getInstance();
    const docPath = getBaseDocPath(configInstance.eventId);
    const map = doc(getFirestore(), docPath, MAPS_COLLECTION, `map_${mapId}`);

    try {
        await updateDoc(map, {
            start: null,
            queue: {},
            positions: {}
        });
        console.log(`Remove start of ${mapId}}!`);
    } catch (error) {
        console.error(`Error removing start of ${mapId}: `, error);
        throw error; // Rethrow the error for further handling
    }
}

