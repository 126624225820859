import React, {useState} from 'react';
import {Button} from '@mui/material';
import {useAlert} from '../../../components/alert/AlertContext';
import {setActiveMap} from '../../../api/domain/map_db';

type ActiveMapButtonProps = {
    mapId: number;
};

export const ActiveMapButton: React.FC<ActiveMapButtonProps> = ({mapId}) => {
    const {addAlert} = useAlert();
    const [loading, setLoading] = useState(false)

    const handleClick = async () => {
        setLoading(true)
        setActiveMap(mapId).then(() => {
            addAlert(`Started map ${mapId} successfully!!`, 'success');
        }).catch((error) => {
            console.error('Failed to update map status:', error);
            addAlert('Failed to update map status. Please try again.', 'error');
            setLoading(false)
        });
    };

    return (
        <Button variant={'outlined'} disabled={loading} onClick={handleClick}>
            Activate
        </Button>
    );
};
