// @ts-nocheck
import {Timestamp} from 'firebase/firestore';
import {MapDocument} from 'peepo-types';


export const map2Data: MapDocument = {
    mapId: 2,
    seed: "9876543210",
    start: Timestamp.now(),  // Current timestamp
    players: {
        "Chaosflo44": {
            runId: "2390594107",
            checkpoint: "END_IN",
            ticks: 22000,  // Original ticks without penalty
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 150.0,
                ENDER_EYE: 4,
                ENDER_PERL: 10
            },
            progressPoints: 60,
            stats: {
                DAMAGE_TAKEN: 900,
                DEATHS: 2,
                END_IN: 8000,
                FINISHED: 22000,
                NETHER_IN: 1500
            },
            resets: [{ runId: "2390594107", ticks: 5000 }],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Nentox": {
            runId: "810328160",
            checkpoint: "FINISHED",
            ticks: 13000,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 20,
                ENDER_EYE: 7,
                ENDER_PERL: 28
            },
            progressPoints: 68,
            stats: {
                DAMAGE_TAKEN: 1200,
                END_IN: 10000,
                FINISHED: 13000,
                NETHER_IN: 1500
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "TheFabo": {
            runId: "441877912",
            checkpoint: "END_IN",
            ticks: 10000,  // Original ticks without penalty
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 3,
                ENDER_PERL: 15
            },
            progressPoints: 55,
            stats: {
                DAMAGE_TAKEN: 100,
                END_IN: 7000,
                FINISHED: 0,
                NETHER_IN: 1000
            },
            resets: [{ runId: "441877912", ticks: 3000 }],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "MultiPori": {
            runId: "133456789",
            checkpoint: "FINISHED",
            ticks: 9500,
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 100.0,
                ENDER_EYE: 2,
                ENDER_PERL: 20
            },
            progressPoints: 62,
            stats: {
                DAMAGE_TAKEN: 450,
                END_IN: 9000,
                FINISHED: 9500,
                NETHER_IN: 2000
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Wichtiger": {
            runId: "989979532",
            checkpoint: "END_IN",
            ticks: 11000,  // Original ticks without penalty
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 70,
                ENDER_EYE: 3,
                ENDER_PERL: 2
            },
            progressPoints: 60,
            stats: {
                DAMAGE_TAKEN: 950,
                END_IN: 9000,
                NETHER_IN: 1500
            },
            resets: [{ runId: "989979532", ticks: 2000 }],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Dimeax": {
            runId: "199999999",
            checkpoint: "FINISHED",
            ticks: 15500,
            progress: {
                BLAZE_ROD: 3,
                DRAGON_HEALTH: 50,
                ENDER_EYE: 5,
                ENDER_PERL: 12
            },
            progressPoints: 65,
            stats: {
                DAMAGE_TAKEN: 850,
                DEATHS: 1,
                END_IN: 8000,
                FINISHED: 15500,
                NETHER_IN: 2500
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "noooreax": {
            runId: "810328160",
            checkpoint: "FINISHED",
            ticks: 13500,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 6,
                ENDER_PERL: 25
            },
            progressPoints: 67,
            stats: {
                DAMAGE_TAKEN: 1300,
                END_IN: 11000,
                FINISHED: 13500,
                NETHER_IN: 1400
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "dimowo": {
            runId: "188888888",
            checkpoint: "END_IN",
            ticks: 14000,  // Original ticks without penalty
            progress: {
                BLAZE_ROD: 2,
                DRAGON_HEALTH: 10,
                ENDER_EYE: 4,
                ENDER_PERL: 18
            },
            progressPoints: 60,
            stats: {
                DAMAGE_TAKEN: 750,
                DEATHS: 1,
                END_IN: 8000,
                FINISHED: 0,
                NETHER_IN: 3000
            },
            resets: [{ runId: "188888888", ticks: 4000 }],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Papaplatte": {
            runId: "177777777",
            checkpoint: "END_IN",
            ticks: 12000,  // Original ticks without penalty
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 5,
                ENDER_EYE: 3,
                ENDER_PERL: 14
            },
            progressPoints: 58,
            stats: {
                DAMAGE_TAKEN: 800,
                DEATHS: 2,
                END_IN: 8000,
                FINISHED: 0,
                NETHER_IN: 2500
            },
            resets: [{ runId: "177777777", ticks: 3000 }],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Finnitz": {
            runId: "166666666",
            checkpoint: "FINISHED",
            ticks: 11500,
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 4,
                ENDER_PERL: 10
            },
            progressPoints: 54,
            stats: {
                DAMAGE_TAKEN: 950,
                DEATHS: 1,
                END_IN: 6000,
                FINISHED: 11500,
                NETHER_IN: 2200
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "Faister": {
            runId: "155555555",
            checkpoint: "END_IN",
            ticks: 12500,  // Original ticks without penalty
            progress: {
                BLAZE_ROD: 0,
                DRAGON_HEALTH: 0,
                ENDER_EYE: 2,
                ENDER_PERL: 12
            },
            progressPoints: 50,
            stats: {
                DAMAGE_TAKEN: 650,
                DEATHS: 1,
                END_IN: 5000,
                FINISHED: 0,
                NETHER_IN: 2000
            },
            resets: [{ runId: "155555555", ticks: 2500 }],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        },
        "mcsr_misfit": {
            runId: "144444444",
            checkpoint: "FINISHED",
            ticks: 13000,
            progress: {
                BLAZE_ROD: 1,
                DRAGON_HEALTH: 30,
                ENDER_EYE: 3,
                ENDER_PERL: 8
            },
            progressPoints: 60,
            stats: {
                DAMAGE_TAKEN: 800,
                DEATHS: 2,
                END_IN: 6000,
                FINISHED: 13000,
                NETHER_IN: 2400
            },
            resets: [],
            updatedAt: Timestamp.now(),
            createdAt: Timestamp.now(),
        }
    },
    queue: {
        "Chaosflo44": Timestamp.now(),
        "TheFabo": Timestamp.now(),
        "Wichtiger": Timestamp.now(),
        "noooreax": Timestamp.now(),
        "Papaplatte": Timestamp.now(),
        "Faister": Timestamp.now()
    }
};
