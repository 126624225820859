import React from 'react';
import styles from './TestDataPage.module.css';
import {useAlert} from '../../../components/alert/AlertContext';
import {Typography} from '@mui/material';
import {Logger, LogLevel} from '../../../utils/Logger';
import {useConfig} from '../../../components/provider/ConfigProvider';
import {InsertTestDataButton} from './InsertTestDataButton';
import {CalculateLeaderboardButton} from './CalculateLeaderboardButton';

type MapsPageProps = {};
const logger = new Logger(LogLevel.INFO, 'TestDataPage', false);

export const TestDataPage: React.FC<MapsPageProps> = (props: MapsPageProps) => {
    const providerConfigData = useConfig();

    const alerts = useAlert();


    return (
        <div className={styles.container}>
            <Typography variant="body1" paragraph>
                You can load pre-existing map documents into the Database to either test the Leaderboard calculation<br/>or provide realistic data for the studio endpoints.
            </Typography>
            <div className={styles.createMapDocumentButton}>
                <InsertTestDataButton/>
            </div>
            <CalculateLeaderboardButton />

        </div>
    );
};
