import React from 'react';
import styles from './StudioPage.module.css';
import {StudioControl} from './control/StudioControl';
import {useBaseDocListener} from '../../hooks/useBaseDocListener';
import {Queue} from './info/Queue';
import {useConfig} from '../../components/provider/ConfigProvider';
import {useConfigListener} from '../../hooks/useConfigListener';

type StudioPageProps = {};

export const StudioPage: React.FC<StudioPageProps> = (props) => {
    const providerConfigData = useConfig();

    return (
        <div className={styles.container}>
            <Queue mapId={providerConfigData.currentMap}/>
            <StudioControl currentLeaderboard={providerConfigData.currentLeaderboard}/>
        </div>
    );
};
