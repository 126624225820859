import * as React from 'react';
import {TeamConfigEditor} from './team/TeamConfigEditor';
import {PlayerConfigEditor} from './player/PlayerConfigEditor';

type Props = {};
export const ConfigEditorPage = (props: Props) => {

    return (
        <div style={{marginTop: 40, marginBottom: 80}}>
            {/* TODO Add editor history */}
            <PlayerConfigEditor />
            <TeamConfigEditor />
        {/*    TODO VERY IMPORTANT ADD METADATA EDITOR FOR QUALIFIER DATE, START DATE, REDIRECT_URL!!!!*/}
        </div>
    );
};
