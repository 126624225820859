import React, {createContext, useContext, useState, useEffect} from 'react';
import * as baseDao from '../../../../api/domain/base_db';
import {useAlert} from '../../../../components/alert/AlertContext';
import {ConfigDocData} from 'peepo-types';
import {useConfig} from '../../../../components/provider/ConfigProvider';


const ConfigContext = createContext<{
    data: ConfigDocData;
    setData: React.Dispatch<React.SetStateAction<ConfigDocData>>;
    updateConfig: (updateFields: Partial<ConfigDocData>) => void;
}>({
    data: {} as ConfigDocData,
    setData: () => {},
    updateConfig: (updateFields) => {},
});

type ConfigProviderProps = {
    children: React.ReactNode;
}

export const ConfigEditProvider: React.FC<ConfigProviderProps> = ({ children }) => {
    const config = useConfig();
    // This data is a copied config object to edit so the editor to keep a controlled component.
    // We use single update Fields to only partially update the config object so it is ok
    const [data, setData] = useState<ConfigDocData>(JSON.parse(JSON.stringify(config)));
    const [saving, setSaving] = useState(false);
    const {addAlert} = useAlert();

    const updateConfig = (configFields: Partial<ConfigDocData>) => {
        if(saving) {
            addAlert('The config is already saving', 'warning');
            return;
        }
        setSaving(true);

        baseDao.updateConfig(data.eventId, configFields).then(() => {
            addAlert('Config successfully saved!', 'success');
        }).catch((error) => {
            addAlert('Failed to save config!', 'error');
            console.error('Failed to save config', error);
        }).finally(() => {
            setSaving(false);
        });
    };


    return (
        <ConfigContext.Provider value={{ data, setData, updateConfig }}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfigEditor = () => {
    const context = useContext(ConfigContext);
    if (context === undefined) {
        throw new Error('useConfig must be used within a ConfigEditrProvider');
    }
    return context;
};
