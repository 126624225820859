.container {
    min-height: 20vh;
}



.createMapDocumentButton {
    display: flex;
    justify-content: end;
    max-width: 80%;
    margin-bottom: 20px;
}
