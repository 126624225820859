import React from 'react';
import {Button} from '@mui/material';
import styles from './PlayerConfigEditor.module.css';
import {PlayerCard} from './PlayerCard';
import Box from '@mui/material/Box';
import {useConfigEditor} from '../form/ConfigEditProvider';
type PlayerConfigEditorProps = {
};

export const PlayerConfigEditor: React.FC<PlayerConfigEditorProps> = ({}) => {
    const { data, setData } = useConfigEditor(); // TODO fix this mess
    const { players } = data;

    const addPlayer = () => {
        setData({ ...data, players: [...players, { name: '', ingName: '', socials: [], team: -1, category: 'unknown' }] });
    };

    const removePlayer = (playerName: string) => {
        const updatedPlayers = players.filter(player => player.name !== playerName);
        setData({ ...data, players: updatedPlayers });
    };

    return (
        <div className={styles.container}>
            <h4>Player Configuration</h4>
            <div className={styles.cardContainer}>
                {players && players.map(player => (
                    <PlayerCard key={player.name} data={player} onRemove={removePlayer} />
                ))}
            </div>

            <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                <Button variant={'contained'} onClick={addPlayer}>Add Player</Button>
            </Box>
        </div>
    );
};
