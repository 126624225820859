/* Assuming you want some additional custom styles */

.seedContainer {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    justify-content: space-between;
}


.eventForm {
    max-width: 300px;

    margin-top: 40px;
    margin-bottom: 40px;
}
