.container {
    margin: 16px;
    padding: 16px;
    width: 400px;
    background-color: #1e1e1e;
    color: white;
    border-radius: 8px;
}

.avatar {
    background-color: #3f51b5;
}
