import * as React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {
    CONFIGURATION_ROUTE,
    DASHBOARD_ROUTE, HIGHLIGHTS_ROUTE,
    LOGIN_ROUTE,
    MAPS_ROUTE, NOT_FOUND_ROUTE,
    PREVIEW_ROUTE, QUALIFIER_ROUTE, RT_DATABASE_ROUTE, STUDIO_ROUTE, TEST_DATA_ROUTE
} from './routerUrls';
import {PreviewPage} from '../pages/PreviewPage';
import {Dashboard} from '../pages/admin/dashboard/Dashboard';
import {ConfigurationPage} from '../pages/admin/config/ConfigurationPage';
import {MapsPage} from '../pages/admin/map/MapsPage';
import {LoginPage} from '../pages/login/LoginPage';
import {ProtectedRoute} from './ProtectedRoute';
import {NotFoundPage} from '../pages/util/NotFoundPage';
import {RTDatabasePage} from '../pages/admin/database/RTDatabasePage';
import {StudioPage} from '../pages/studio/StudioPage';
import {QualifierPage} from '../pages/admin/qualifier/QualifierPage';
import {HighlightsPage} from "../pages/highlights/HighlightsPage";
import {TestDataPage} from '../pages/admin/testData/TestDataPage';


export const PeepoRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path={PREVIEW_ROUTE} element={<PreviewPage/>}/>
            <Route path={NOT_FOUND_ROUTE} element={<NotFoundPage />}/>
            <Route path={LOGIN_ROUTE} element={<LoginPage />}/>

            <Route path={DASHBOARD_ROUTE} element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
            <Route path={RT_DATABASE_ROUTE} element={<ProtectedRoute><RTDatabasePage/></ProtectedRoute>}/>
            <Route path={QUALIFIER_ROUTE} element={<ProtectedRoute><QualifierPage/></ProtectedRoute>}/>

            <Route path={CONFIGURATION_ROUTE} element={<ProtectedRoute><ConfigurationPage/></ProtectedRoute>}/>

            <Route path={MAPS_ROUTE} element={<ProtectedRoute><MapsPage/></ProtectedRoute>}/>

            <Route path={STUDIO_ROUTE} element={<ProtectedRoute><StudioPage/></ProtectedRoute>}/>
            <Route path={HIGHLIGHTS_ROUTE} element={<ProtectedRoute><HighlightsPage/></ProtectedRoute>}/>
            <Route path={TEST_DATA_ROUTE} element={<ProtectedRoute><TestDataPage/></ProtectedRoute>}/>
            <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
            />
        </Routes>
    );
};
