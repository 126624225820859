.container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #121212;
    z-index: 100000;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.highlightContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: center;
    margin-top: 20px;
}

.informationContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 3px;
    padding: 20px;
    align-content: center;
    text-align: center;
    margin-top: 20px;
}

.ratingContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    margin-top: 20px;
}

.ratingButton {
    background-color: rgba(225, 173, 0, 0);
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.ratingButton:hover {
    background-color: #e1ad00;
}
