.container {
    min-height: 20vh;
}


.buttonContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
}



.seedContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    align-items: center;
    margin-top: 20px;
}
