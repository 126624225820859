import React from 'react';
import {Navigate} from 'react-router-dom';
import {LOGIN_ROUTE} from './routerUrls';
import {getAuth} from 'firebase/auth';


const isLoggedIn = () => {
    return getAuth().currentUser;
};


export const ProtectedRoute: React.FC<React.PropsWithChildren> = ({children}) => {
    const loggedIn = isLoggedIn();
    return loggedIn ? <>{children}</> : <Navigate to={LOGIN_ROUTE} replace/>;
};
