"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTime = exports.getRemainingTime = void 0;
function getRemainingTime(targetDate) {
    const currentTime = new Date().getTime();
    const difference = (targetDate === null || targetDate === void 0 ? void 0 : targetDate.getTime()) - currentTime;
    if (difference <= 0) {
        // Target date has already been reached
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    }
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return {
        days,
        hours,
        minutes,
        seconds,
    };
}
exports.getRemainingTime = getRemainingTime;
function getTime(ticks) {
    if (ticks === -1 || ticks === 0) {
        return '-';
    }
    const secondsTotal = ticks / 20;
    const secondsInt = Math.floor(secondsTotal % 60);
    const secondsDecimals = ((secondsTotal % 60) - secondsInt).toFixed(2).slice(2);
    const minutes = Math.floor(secondsTotal / 60 % 60);
    return `${toDigitString(minutes)}:${toDigitString(secondsInt)}.${secondsDecimals}`;
}
exports.getTime = getTime;
function toDigitString(x) {
    return String(x).padStart(2, '0');
}
