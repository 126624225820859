import {
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    query,
    runTransaction,
    serverTimestamp,
    setDoc,
    Timestamp,
    where
} from 'firebase/firestore';
import {getBaseDocPath, getConfigDocPath} from '../../firebase/firebase';
import {BaseDoc, ConfigDocData, ConsoleLogger, EventFormat, LogLevel, MapDocument} from 'peepo-types';
import {DEFAULT_CONFIG} from '../../pages/util/create_data';
import {PeepoSprintConfigData} from '../../utils/PeepoSprintConfigData';
import {CONFIG_DOC, PEEPOSPRINT_COLLECTION} from '../../../peepo-types/src/firestore/collection_const';

const logger = new ConsoleLogger(LogLevel.DEBUG, 'BaseDB', true);

export const fetchBaseDoc = async () => {
    try {
        const baseDocRef = doc(getFirestore(), 'peepoSprint', 'version10');
        const docSnap = await getDoc(baseDocRef);
        return docSnap.data() as BaseDoc;
    } catch (error) {
        logger.error('Error fetching map document: ', error);
        throw error; // Rethrow the error for further handling
    }
}

export const listenBaseDoc = async (callback: (data: BaseDoc | undefined) => void) => {
    const configInstance = await PeepoSprintConfigData.getInstance();
    const docPath = getBaseDocPath(configInstance.eventId);
    const baseDocRef = doc(getFirestore(), docPath);

    return onSnapshot(baseDocRef, (docSnap) => {
        logger.debug('listenedToBaseUpdate updated with ID: ', docSnap.id);
        callback(docSnap.data() as BaseDoc);
    }, (error) => {
        logger.error('Error fetching map document: ', error);
    });
}

export const fetchBaseDocs = async (): Promise<BaseDoc[]> => {
    const db = getFirestore();

    const peepoSprintCollection = collection(db, 'peepoSprint');
    const peepoSprintQuery = query(peepoSprintCollection, where('__name__', '!=', 'config'));
    try {
        const querySnapshot = await getDocs(peepoSprintQuery);
        return querySnapshot.docs.map(doc => doc.data() as BaseDoc);
    } catch (error) {
        logger.error('Error fetching documents: ', error);
        throw error; // Rethrow the error for further handling
    }
}


export const listenPeepoSprintConfig = async (callback: (data: ConfigDocData | undefined) => void) => {
    const baseDocRef = doc(getFirestore(), getConfigDocPath());

    return onSnapshot(baseDocRef, (docSnap) => {
        logger.debug('listenedToConfigUpdate updated with ID: ', docSnap.id);
        callback(docSnap.data() as ConfigDocData);
    }, (error) => {
        logger.error('Error fetching map document: ', error);
    });
}

export const listenPeepoBaseDoc = async (callback: (data: BaseDoc | undefined) => void) => {
    const configInstance = await PeepoSprintConfigData.getInstance();
    const docPath = getBaseDocPath(configInstance.eventId);
    const baseDocRef = doc(getFirestore(), docPath);

    return onSnapshot(baseDocRef, (docSnap) => {
        logger.info('listenedToBaseUpdate updated with ID: ', docSnap.id);
        callback(docSnap.data() as BaseDoc);
    }, (error) => {
        logger.error('Error fetching map document: ', error);
    });
}


export const updateCurrentLeaderboard = async (currentLeaderboard: string) => {
    const db = getFirestore();

    try {
        const baseDocRef = doc(db, getConfigDocPath());
        await setDoc(baseDocRef, {
            currentLeaderboard
        }, {merge: true});
        logger.info('Document written with ID: ', baseDocRef.id);
    } catch (error) {
        logger.error('Error fetching map document: ', error);
        throw error; // Rethrow the error for further handling
    }
}


export const writeConfig = async (data: ConfigDocData) => {
    const db = getFirestore();

    try {
        const docRef = doc(db, 'peepoSprint', 'config');
        const baseDocRef = doc(db, 'peepoSprint', data.eventId);
        await setDoc(docRef, data, {merge: true});
        await setDoc(baseDocRef, {
            config: data
        }, {merge: true});
        logger.info('Document written with ID: ', docRef.id);
    } catch (error) {
        logger.error('Error fetching map document: ', error);
        throw error; // Rethrow the error for further handling
    }
}

export const updateConfig = async (eventId, data) => {
    const db = getFirestore();

    try {
        const docRef = doc(db, 'peepoSprint', 'config');
        const baseDocRef = doc(db, 'peepoSprint', eventId);

        // Merging the main config document
        await setDoc(docRef, data, { merge: true });

        // Merging the event-specific config
        await setDoc(baseDocRef, { config: data }, { merge: true });

        logger.info('Config updated with ID: ', docRef.id);
    } catch (error) {
        logger.error('Error updating config document: ', error);
        throw error; // Rethrow the error for further handling
    }
}

export const writeDefaultConfiguration = async (data: ConfigDocData, maps: MapDocument[]) => {
    const db = getFirestore();
    const seeds = maps.map(map => map.seed);
    try {
        const docRef = doc(db, 'peepoSprint', 'config');
        const baseDocRef = doc(db, 'peepoSprint', data.eventId);
        await setDoc(docRef, data);
        await setDoc(baseDocRef, {
            config: data,
            seeds,
            currentSeed: seeds[0],
            currentLeaderboard: 1
        }, {merge: true});
        const configInstance = await PeepoSprintConfigData.getInstance();
        const docPath = getBaseDocPath(configInstance.eventId);
        const mapsCollectionRef = collection(db, docPath, 'maps');
        for (let map of maps) {
            await setDoc(doc(mapsCollectionRef, `seed_${map.seed}`), map);
            logger.info('MapDocument created ', map.mapId);
        }
        logger.info('Document written with ID: ', docRef.id);

    } catch (error) {
        logger.error('Error fetching map document: ', error);
        throw error; // Rethrow the error for further handling
    }
}


// Create a new peepoSprintEventDoc with the current config. In addition the
// current config will be saved into the old EventDoc
export const createBaseDoc = async (eventId: string, format: EventFormat, start: Date) => {
    if (!/^[a-zA-Z0-9_-]*$/.test(eventId)) {
        throw new Error('Invalid eventId format. Only alphanumeric characters, underscore and dash are allowed, without any spaces.');
    }
    const db = getFirestore();
    logger.info('Creating base doc with eventId: ', eventId);
    try {
        await runTransaction(db, async (transaction) => {
            const baseDocRef = doc(db, PEEPOSPRINT_COLLECTION, eventId);
            const configDocRef = doc(db, PEEPOSPRINT_COLLECTION, CONFIG_DOC);

            const baseDoc = await transaction.get(baseDocRef);
            if (baseDoc.exists()) {
                logger.error('Document already exists');
                throw new Error('Document already exists');
            }

            const configDoc = await transaction.get(configDocRef);
            if (!configDoc.exists()) {
                logger.error('Config document does not exist');
                throw new Error('Config document does not exist');
            }
            const configData = configDoc.data() as ConfigDocData;
            logger.info('Config data: ', configData);
            // @ts-ignore
            transaction.set(doc(db, PEEPOSPRINT_COLLECTION, configData.eventId), {
                config: configData
            }, {merge: true});

            const data = {
                currentSeed: '',
                currentLeaderboard: 1,
                createdAt: serverTimestamp(),
                eventId,
                start: Timestamp.fromDate(start)
            };
            logger.info('Creating base doc with data: ', data);
            transaction.set(baseDocRef, data);

            const data1 = {
                ...DEFAULT_CONFIG,
                eventId,
                format,
            };
            logger.info('Creating config doc with data: ', data1);
            transaction.set(configDocRef, data1);
        });
    } catch (error) {
        logger.error('Error creating base doc', error);
        throw error; // Rethrow the error for further handling
    }
}



