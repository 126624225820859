.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.sideBar {
    padding: 60px 40px;
    text-align: center;
}
