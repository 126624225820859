import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
import {DASHBOARD_ROUTE} from '../../router/routerUrls'; // Import auth from firebase

const auth = getAuth();

type Props = {
    // You can add props here if needed
};
export const LoginForm: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setEmailError(false);
        setPasswordError(false);

        if (username === '') {
            setEmailError(true);
        }

        if (password === '') {
            setPasswordError(true);
        }

        if (username === '' || password === '') {
            return;
        }

        try {
            await signInWithEmailAndPassword(auth, username, password);
            navigate(DASHBOARD_ROUTE);
        } catch (error) {
            // @ts-ignore
            if (error.code) {
                // @ts-ignore
                if (error.code === 'auth/wrong-password') {
                    setError('The password is incorrect.');
                    setPasswordError(true);
                    // @ts-ignore
                } else if (error.code === 'auth/user-not-found') {
                    setError('The email does not exist.');
                    setEmailError(true);
                } else {
                    setError('An unknown error occurred.');
                }
            } else {
                console.warn('Error during login', error);
            }
        }
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '25ch'},
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <TextField
                required
                id="username"
                label="Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={emailError}
                helperText={emailError ? 'Username is required' : ''}
            />
            <TextField
                required
                id="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError}
                helperText={passwordError ? 'Password is required' : ''}
            />
            {error && <p>{error}</p>}
            <Button type="submit" variant="contained" sx={{mt: 3, mb: 2}}>
                Login
            </Button>
        </Box>
    );
};
