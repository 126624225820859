.paper {
    padding: 30px 40px;
    width: 320px;
    position: relative;
}
.inputField {
    width: 100%;
}


.fieldContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}


.deleteButton {
    position: absolute;
    top: 20px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.deleteButton:hover {
    background-color: #f44336;
    color: white;
}
