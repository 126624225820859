export const PREVIEW_ROUTE = '/preview';


export const DASHBOARD_ROUTE = '/dashboard';
export const MAPS_ROUTE = '/maps';
export const CLIPS_ROUTE = '/clips';
export const CONFIGURATION_ROUTE = '/settings';
export const RT_DATABASE_ROUTE = '/rtdb';
export const QUALIFIER_ROUTE = '/qualifier';

export const STUDIO_ROUTE = '/studio';
export const mapsDetailRoute = (seed: string) => `/map/${seed}`;
export const HIGHLIGHTS_ROUTE = '/highlights';

export const TEST_DATA_ROUTE = '/data';

export const NOT_FOUND_ROUTE = '/not-found';
export const LOGIN_ROUTE = '/login';

