import {McsrPlayerResponse, QualifierPlayerData} from '../../../types/qualifier';

export const fetchMcsrPlayerData = async (identifier: string): Promise<McsrPlayerResponse | undefined> => {
    try {
        const response = await fetch(`https://mcsrranked.com/api/users/${identifier}`);
        if (!response.ok) {
            console.error(`Failed to fetch data for player ${identifier}`);
            return undefined;
        }
        const jsonResponse = await response.json();
        return jsonResponse.data as McsrPlayerResponse;
    } catch (error) {
        console.error(`Error on executing the network request ${identifier}:`, error);
        return undefined;
    }
};



export const leaderBoardTestMembers = [
    { identifier: "doogile", playerName: "doogile", email: "doogile@peeposprint.at", elo: 2157, faulty: false },
    { identifier: "lowk3y_", playerName: "lowk3y_", email: "lowk3y_@peeposprint.at", elo: 2092, faulty: false },
    { identifier: "bing_pigs", playerName: "bing_pigs", email: "bing_pigs@peeposprint.at", elo: 2052, faulty: false },
    { identifier: "Ancoboyy", playerName: "Ancoboyy", email: "Ancoboyy@peeposprint.at", elo: 2024, faulty: false },
    { identifier: "DziabaDziuba", playerName: "DziabaDziuba", email: "DziabaDziuba@peeposprint.at", elo: 1962, faulty: false },
    { identifier: "silverrruns", playerName: "silverrruns", email: "silverrruns@peeposprint.at", elo: 1920, faulty: false },
    { identifier: "TUDORULE", playerName: "TUDORULE", email: "TUDORULE@peeposprint.at", elo: 1910 , faulty: false},
    { identifier: "Emillk", playerName: "Emillk", email: "Emillk@peeposprint.at", elo: 1902, faulty: false },
    { identifier: "v_strid", playerName: "v_strid", email: "v_strid@peeposprint.at", elo: 1886 , faulty: false},
    { identifier: "Mar1n", playerName: "Mar1n", email: "Mar1n@peeposprint.at", elo: 1863, faulty: false },
    { identifier: "dandannyboy", playerName: "dandannyboy", email: "dandannyboy@peeposprint.at", elo: 1856, faulty: false },
    { identifier: "Hinart", playerName: "Hinart", email: "Hinart@peeposprint.at", elo: 1849 , faulty: false},
    { identifier: "7rowl", playerName: "7rowl", email: "7rowl@peeposprint.at", elo: 1838 , faulty: false},
    { identifier: "Erikfzf", playerName: "Erikfzf", email: "Erikfzf@peeposprint.at", elo: 1824, faulty: false },
    { identifier: "Cube1337x", playerName: "Cube1337x", email: "Cube1337x@peeposprint.at", elo: 1818, faulty: false },
    { identifier: "dolqhin", playerName: "dolqhin", email: "dolqhin@peeposprint.at", elo: 1814, faulty: false },
    { identifier: "thecamo6", playerName: "thecamo6", email: "thecamo6@peeposprint.at", elo: 1813, faulty: false },
    { identifier: "MrBudgiee", playerName: "MrBudgiee", email: "MrBudgiee@peeposprint.at", elo: 1810 , faulty: false},
    { identifier: "hackingnoises", playerName: "hackingnoises", email: "hackingnoises@peeposprint.at", elo: 1808 , faulty: false},
    { identifier: "KenanKardes", playerName: "KenanKardes", email: "KenanKardes@peeposprint.at", elo: 1805, faulty: false },
    { identifier: "Bloonskiller", playerName: "Bloonskiller", email: "Bloonskiller@peeposprint.at", elo: 1803, faulty: false },
    { identifier: "Oxidiot", playerName: "Oxidiot", email: "Oxidiot@peeposprint.at", elo: 1793, faulty: false },
    { identifier: "Aquacorde", playerName: "Aquacorde", email: "Aquacorde@peeposprint.at", elo: 1755, faulty: false },
    { identifier: "BadGamer", playerName: "BadGamer", email: "BadGamer@peeposprint.at", elo: 1755 , faulty: false},
    { identifier: "sanjinhu", playerName: "sanjinhu", email: "sanjinhu@peeposprint.at", elo: 1741 , faulty: false},
    { identifier: "Kxpow", playerName: "Kxpow", email: "Kxpow@peeposprint.at", elo: 1736 , faulty: false},
    { identifier: "BeefSalad", playerName: "BeefSalad", email: "BeefSalad@peeposprint.at", elo: 1728 , faulty: false},
    { identifier: "Priffin", playerName: "Priffin", email: "Priffin@peeposprint.at", elo: 1719 , faulty: false},
    { identifier: "ulsah1n", playerName: "ulsah1n", email: "ulsah1n@peeposprint.at", elo: 1715 , faulty: false},
    { identifier: "Javierixmon", playerName: "Javierixmon", email: "Javierixmon@peeposprint.at", elo: 1711, faulty: false },
    { identifier: "tommorerow", playerName: "tommorerow", email: "tommorerow@peeposprint.at", elo: 1710 , faulty: false},
    { identifier: "meebie", playerName: "meebie", email: "meebie@peeposprint.at", elo: 1707, faulty: false },
    { identifier: "k4yfour", playerName: "k4yfour", email: "k4yfour@peeposprint.at", elo: 1705 , faulty: false},
    { identifier: "sockmonkey747", playerName: "sockmonkey747", email: "sockmonkey747@peeposprint.at", elo: 1697 , faulty: false},
    { identifier: "Waluyoshi", playerName: "Waluyoshi", email: "Waluyoshi@peeposprint.at", elo: 1695, faulty: false },
    { identifier: "mcsr_lebron", playerName: "mcsr_lebron", email: "mcsr_lebron@peeposprint.at", elo: 1687 , faulty: false},
    { identifier: "emia", playerName: "emia", email: "emia@peeposprint.at", elo: 1686, faulty: false },
    { identifier: "Tookannn", playerName: "Tookannn", email: "Tookannn@peeposprint.at", elo: 1684 , faulty: false},
    { identifier: "big_pizu", playerName: "big_pizu", email: "big_pizu@peeposprint.at", elo: 1676 , faulty: false},
    { identifier: "Finnitz", playerName: "Finnitz", email: "Finnitz@peeposprint.at", elo: 1671, faulty: false },
    { identifier: "SuperC_", playerName: "SuperC_", email: "SuperC_@peeposprint.at", elo: 1669 , faulty: false},
    { identifier: "ColeTM", playerName: "ColeTM", email: "ColeTM@peeposprint.at", elo: 1661 , faulty: false},
    { identifier: "90Tom", playerName: "90Tom", email: "90Tom@peeposprint.at", elo: 1659, faulty: false },
    { identifier: "loodlow", playerName: "loodlow", email: "loodlow@peeposprint.at", elo: 1653 , faulty: false},
    { identifier: "rekrap2", playerName: "rekrap2", email: "rekrap2@peeposprint.at", elo: 1651 , faulty: false},
    { identifier: "HanoPower_", playerName: "HanoPower_", email: "HanoPower_@peeposprint.at", elo: 1647, faulty: false },
    { identifier: "Mauvu", playerName: "Mauvu", email: "Mauvu@peeposprint.at", elo: 1646, faulty: false },
    { identifier: "JackoWacko62", playerName: "JackoWacko62", email: "JackoWacko62@peeposprint.at", elo: 1643 , faulty: false},
    { identifier: "retropog", playerName: "retropog", email: "retropog@peeposprint.at", elo: 1642, faulty: false },
    { identifier: "dillrice", playerName: "dillrice", email: "dillrice@peeposprint.at", elo: 1640 , faulty: false},
    { identifier: "ANJOUU", playerName: "ANJOUU", email: "ANJOUU@peeposprint.at", elo: 1639 , faulty: false},
    { identifier: "megelimc", playerName: "megelimc", email: "megelimc@peeposprint.at", elo: 1637, faulty: false },
    { identifier: "HDMICables", playerName: "HDMICables", email: "HDMICables@peeposprint.at", elo: 1631, faulty: false },
    { identifier: "Cornflakes267", playerName: "Cornflakes267", email: "Cornflakes267@peeposprint.at", elo: 1631, faulty: false },
    { identifier: "Thunderstorming", playerName: "Thunderstorming", email: "Thunderstorming@peeposprint.at", elo: 1628 , faulty: false},
    { identifier: "RRed", playerName: "RRed", email: "RRed@peeposprint.at", elo: 1626, faulty: false },
    { identifier: "mcpanda123", playerName: "mcpanda123", email: "mcpanda123@peeposprint.at", elo: 1625 , faulty: false},
    { identifier: "v4lenXD", playerName: "v4lenXD", email: "v4lenXD@peeposprint.at", elo: 1621 , faulty: false},
    { identifier: "paplerr", playerName: "paplerr", email: "paplerr@peeposprint.at", elo: 1615, faulty: false },
    { identifier: "nEmerald", playerName: "nEmerald", email: "nEmerald@peeposprint.at", elo: 1607, faulty: false },
    { identifier: "drx6", playerName: "drx6", email: "drx6@peeposprint.at", elo: 1606, faulty: false },
    { identifier: "Sneedcopter", playerName: "Sneedcopter", email: "Sneedcopter@peeposprint.at", elo: 1605, faulty: false },
    { identifier: "Sadekeppi", playerName: "Sadekeppi", email: "Sadekeppi@peeposprint.at", elo: 1601 , faulty: false},
    { identifier: "Tenes9999", playerName: "Tenes9999", email: "Tenes9999@peeposprint.at", elo: 1596, faulty: false },
    { identifier: "_loro1", playerName: "_loro1", email: "_loro1@peeposprint.at", elo: 1595, faulty: false },
    { identifier: "NottAntoo", playerName: "NottAntoo", email: "NottAntoo@peeposprint.at", elo: 1595, faulty: false },
    { identifier: "MuhammadPro5741", playerName: "MuhammadPro5741", email: "MuhammadPro5741@peeposprint.at", elo: 1595, faulty: false },
    { identifier: "xLourx", playerName: "xLourx", email: "xLourx@peeposprint.at", elo: 1593, faulty: false },
    { identifier: "Winstonein", playerName: "Winstonein", email: "Winstonein@peeposprint.at", elo: 1593, faulty: false },
];

