import React, { createContext, PropsWithChildren, useContext, useEffect, useState, useMemo } from 'react';
import { listenPeepoSprintConfig } from '../../api/domain/base_db';
import { useAuth } from './AuthProvider';
import { Logger, LogLevel } from '../../utils/Logger';
import { Loading } from '../Loading';
import { ProviderConfigData } from './ProviderConfigData';

const context = createContext<ProviderConfigData | undefined>(undefined);
const logger = new Logger(LogLevel.INFO, 'ConfigProvider', true);

type NetworkStateType = {
    data?: ProviderConfigData;
    error: boolean;
    errorMessage: string;
    loading: boolean;
    ready: boolean;
};

export const ConfigProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const auth = useAuth();
    const [networkState, setNetworkState] = useState<NetworkStateType>({
        error: false,
        errorMessage: '',
        loading: true,
        ready: false,
    });

    useEffect(() => {
        let isMounted = true; // To prevent state update if component is unmounted

        if (!auth.user) {
            logger.error('User is not authenticated');
            setNetworkState(prevState => ({
                ...prevState,
                error: true,
                errorMessage: 'User is not authenticated',
                loading: false
            }));
            return;
        }

        const fetchData = async () => {
            try {
                return await listenPeepoSprintConfig((data) => {
                    if (!data) {
                        logger.error('No data found');
                        if (isMounted) {
                            setNetworkState(prevState => ({
                                ...prevState,
                                error: true,
                                errorMessage: 'No data found',
                                loading: false
                            }));
                        }
                        return;
                    }
                    logger.info('Data found', data);
                    const peepoSprintConfig = new ProviderConfigData(data!);
                    if (isMounted) {
                        setNetworkState({
                            data: peepoSprintConfig,
                            loading: false,
                            error: false,
                            errorMessage: '',
                            ready: true,
                        });
                    }
                });
            } catch (error) {
                logger.error('Error fetching data', error);
                if (isMounted) {
                    setNetworkState(prevState => ({
                        ...prevState,
                        error: true,
                        errorMessage: error.message,
                        loading: false,
                    }));
                }
            }
        };

        const unsubscribePromise = fetchData();

        return () => {
            isMounted = false;
            unsubscribePromise.then((unsubscribe) => unsubscribe && unsubscribe());
        };
    }, [auth.user]);

    const contextValue = useMemo(() => networkState.data, [networkState.data]);

    if (networkState.loading) {
        return <Loading />;
    }

    if (networkState.error) {
        if (!auth.user) {
            return (
                <>
                    {children}
                    <div>Error: {networkState.errorMessage}</div>
                </>
            );
        } else {
            return <div>Error: {networkState.errorMessage}</div>;
        }
    }

    return (
        <context.Provider value={contextValue}>
            {children}
        </context.Provider>
    );
};

export const useConfig = (): ProviderConfigData => {
    const config = useContext(context);
    if (!config) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return config;
};
