.container {
    min-height: 20vh;
}


.paper {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
}


.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
}
