import React, {useState} from 'react';
import styles from './MapCard.module.css';
import {useNavigate} from 'react-router-dom';
import {mapsDetailRoute} from '../../../router/routerUrls';
import {Chip, Paper} from '@mui/material';
import {Checkpoint} from '../../../utils/leaderboard_const';
import Button from '@mui/material/Button';
import {StartMapButton} from './StartMapButton';
import {ActiveMapButton} from './ActiveMapButton';
import {MapDocument} from 'peepo-types';
import {useConfig} from '../../../components/provider/ConfigProvider';
import {resetStart} from "../../../api/domain/map_db";

type SeedCardProps = {
    data: MapDocument;
    active: boolean;
    rerender: () => void;
};

export const MapCard: React.FC<SeedCardProps> = ({data, active, rerender}: SeedCardProps) => {
    const configData = useConfig();
    const [started, setStarted] = useState(!!data.start)
    const {mapId, seed, players, queue} = data;

    const resetStartDate = () => {
        resetStart(mapId).then(r => {
            setStarted(false);
            rerender();
        });
    };
    const maxPlayers = configData.players.length;
    const finishedPlayerCount = Object.values(players).filter((player) => player.checkpoint === Checkpoint.FINISHED).length;
    const playerCount = Object.keys(players).length;
    const queuedCount = Object.keys(queue).length;
    const isStarted = data.start !== undefined;

    return (
        <Paper className={`${styles.container} ${active ? styles.active : ''} ${started ? styles.started : ''}`}>
            <div>
                <h4>Map {mapId}{active && <Chip label={'Active'} size="small" color={'success'} sx={{ml: 2, mb: 1}} />}</h4>
                <p>Seed: {seed}</p>
            </div>
            <div className={styles.switchPosition}>
                {active && <StartMapButton mapId={mapId} seed={seed} started={started} onStart={() => {
                    setStarted(true);
                    rerender();
                }}/> }
                {!active && <ActiveMapButton mapId={mapId} /> }
            </div>

            <div className={styles.statContainer}>
                <p>Queued: {queuedCount}</p>
                <p>Players: ({playerCount}/{maxPlayers})</p>
                <p>Finished: ({finishedPlayerCount}/{maxPlayers})</p>
                <p>Started: {data.start ? data.start.toDate().toTimeString().split('GMT')[0] : '-'}</p>
            </div>
            <div className={styles.buttonContainer}>
                <Button variant={'outlined'} onClick={resetStartDate} disabled={!started}>Reset Start</Button>
            </div>
        </Paper>
    );
};
